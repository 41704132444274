import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExplanationsFieldObserverService {
  private explanationDataSubject: Subject<boolean> = new Subject<boolean>();
  public explanationData$: Observable<boolean> =
    this.explanationDataSubject.asObservable();

  constructor() {}

  updateExplanationData(newExplanationData: boolean): void {
    this.explanationDataSubject.next(newExplanationData);
  }
}
