<oaman-detail-actions
  [actions]="requestsActions"
  [fundingProject]="fundingProject"
  (clickTriggered)="openDialog($event)"
></oaman-detail-actions>
<div class="table-wrapper">
  <mat-table
    id="requests-table"
    [dataSource]="dataSource"
    matSort
    matSortActive="createdDate"
    matSortDirection="asc"
  >
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell
        [title]="'fundingProject.details.requests.table.a11y.type' | translate"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.requests.table.type' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ 'fundingProject.' + element.type | translate }}
      </mat-cell>
    </ng-container>

    <!-- submissionDate Column -->
    <ng-container matColumnDef="submissionDate">
      <mat-header-cell
        [title]="
          'fundingProject.details.requests.table.a11y.submissionDate'
            | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.requests.table.submissionDate' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.submissionDate | date }}
      </mat-cell>
    </ng-container>

    <!-- submittedBy Column -->
    <ng-container matColumnDef="submissionBy">
      <mat-header-cell
        [title]="
          'fundingProject.details.requests.table.a11y.submissionBy' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.requests.table.submittedBy' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.submissionBy }}
      </mat-cell>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell
        [title]="
          'fundingProject.details.requests.table.a11y.status' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.requests.table.status' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ 'fundingProject.' + element.status | translate }}
      </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <mat-header-cell class="last-column" *matHeaderCellDef></mat-header-cell>
      <mat-cell class="last-column" *matCellDef="let element">
        <mat-icon
          #matMenuTrigger="matMenuTrigger"
          (keyup.enter)="onKeyupEnter()"
          [matMenuTriggerFor]="menu"
          matTooltip="{{
            'fundingProject.details.documents.table.more' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.documents.table.more' | translate
          }}"
          class="action-icon"
          type="button"
          tabindex="0"
          *ngIf="element"
          >more_horiz
        </mat-icon>
        <mat-menu #menu="matMenu" xPosition="before">
          <div
            *ngIf="this.context === 'enroll'"
            matTooltip="{{
              getDropdownTooltip('request', element) | translate
            }}"
            [matTooltipDisabled]="!isButtonDisabled('request', element)"
          >
            <button
              [disabled]="isButtonDisabled('request', element)"
              (click)="triggerDropdownAction('request', element)"
              mat-menu-item
              class="dropdown-item"
            >
              {{
                'fundingProject.details.requests.table.dropdown.request'
                  | translate
              }}
            </button>
          </div>
          <div
            *ngIf="this.context === 'enroll' && element.status !== 'request.state.submitted'"
            matTooltip="{{ getDropdownTooltip('delete', element) | translate }}"
            [matTooltipDisabled]="!isButtonDisabled('delete', element)"
          >
            <button
              [disabled]="isButtonDisabled('delete', element)"
              (click)="triggerDropdownAction('delete', element)"
              mat-menu-item
              class="dropdown-item"
            >
              {{
                element.status === 'request.state.created'
                  ? ('fundingProject.details.requests.table.dropdown.delete'
                    | translate)
                  : ('fundingProject.details.requests.table.dropdown.withdraw'
                    | translate)
              }}
            </button>
          </div>
          <mat-divider *ngIf="this.context === 'enroll'"></mat-divider>

          <button
            (click)="triggerDropdownAction('forms', element)"
            mat-menu-item
            class="dropdown-item"
          >
            {{
              'fundingProject.details.requests.table.dropdown.showForms'
                | translate
            }}
          </button>
          <button
            (click)="triggerDropdownAction('documents', element)"
            mat-menu-item
            class="dropdown-item"
          >
            {{
              'fundingProject.details.requests.table.dropdown.showDocuments'
                | translate
            }}
          </button>
          <div
            matTooltip="{{
              'fundingProject.details.requests.table.dropdown.noticeTooltip'
                | translate
            }}"
          >
          </div>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row
      id="header-row"
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    id="paginator"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
  <p class="tz-hint">
    {{ 'fundingProject.details.documents.table.timezone' | translate }}
    {{ currentDate | date: 'zzzz' }}
  </p>
</div>
