import { Injectable } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { startWith } from 'rxjs/operators';
import { FundingMeasure } from '../../../../../core/interfaces/funding-measure';
import { AbstractControl } from '@angular/forms';
import * as FormConstants from '../utils/forms-constants';
import {
  FORM_TEMPLATE_ZILE,
  FUNDING_MEASURE_DORFENTWICKLUNGSPLAN,
  LEGAL_FORM_EHELEUTE,
  LEGAL_FORM_GESELLSCHAFT_DES_BUERGERLICHEN_RECHTS,
  LEGAL_FORM_KORPERSCHAFT_OFFENTLICHES_RECHT,
  LEGAL_FORM_SONSTIGE_GEBIETKORPERSCHAFT,
  LEGAL_FORM_SONSTIGE_PERSONENGESELLSCHAFTEN
} from '../utils/forms-constants';
import { User } from '../../../../../core/interfaces/user';
import { LocalActionGroup } from '../../../../../core/interfaces/local-action-group';
import { DeputyInfo } from '../../../../../core/interfaces/deputy-info';
import {
  FormsAttorneyPowerTypeAvailability,
  getAttorneyPowerTypes
} from '../../../../../core/interfaces/attorny-power-info';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Nation } from '../../../../../core/interfaces/nation';
import { MultiselectCheckboxData } from '../interfaces/multiselect-checkbox-data';

interface FormProjectRepresentative {
  lastname: string;
  firstname: string;
  oamanAccessValidFrom: string;
  oamanAccessValidTo?: string;
  attorneyPowerType: string;
  attorneyPowerStartDate?: string;
  attorneyPowerEndDate?: string;
  attorneyPowerAvailability: string;
}

@Injectable({ providedIn: 'root' })
export class FormsHelper {
  private immutableSubGroupZile: Readonly<FormlyFieldConfig[] | undefined>; // for ZILE conditional rendering
  private immutableSubGroupCommon: Readonly<FormlyFieldConfig[] | undefined>;
  private immutableSubGroupCompany: Readonly<FormlyFieldConfig[] | undefined>; // for ZILE & LEADER conditional rendering
  private legalFormControlSubscription!: Subscription | undefined;

  constructor(private translateService: TranslateService) {}

  /**
   * Method implements conditional visibility for fields/sections in the page-group "Angaben zum Vorhaben" depending on the choosen funding measure
   * @param fields
   * @param options
   */
  public renderZileConditions(
    fields: FormlyFieldConfig[],
    options: FormlyFormOptions
  ) {
    // get fundingMeasure formControl
    const fundingMeasureControl =
      fields[0].fieldGroup?.[0].fieldGroup?.[0].fieldGroup?.[0].fieldGroup?.[1].form?.get(
        'fundingMeasure'
      );

    //=====================================Vorhaben==========================================

    // conditional visibility for field with key "project_dorfentwicklungsplaene"
    fields[0].fieldGroup![2].fieldGroup![0].fieldGroup![2]['hooks'] =
      this.createHook(
        fields[0].fieldGroup![2].fieldGroup![0].fieldGroup![2],
        fields[0].fieldGroup![2].fieldGroup![0].fieldGroup![2],
        fundingMeasureControl,
        [FormConstants.FUNDING_MEASURE_DORFENTWICKLUNGSPLAN],
        options
      );
  }

  /**
   * Method implements conditional visibility for sections in the page-group "Erklärungen und Anlagen" depending on the choosen legal form
   * @param fields list of form fields
   */
  public renderCommonConditions(fields: FormlyFieldConfig[]) {
    const soleProprietorControl =
      fields[0].fieldGroup?.[1].fieldGroup?.[0].fieldGroup?.[0].fieldGroup?.[0].form?.get(
        'soleProprietor_R60000035'
      );

    soleProprietorControl?.valueChanges
      .pipe(startWith(soleProprietorControl?.value))
      .subscribe((selectedSoleProprietor) => {
        if (selectedSoleProprietor) {
          if (fields[0].fieldGroup![4].fieldGroup?.length === 8) {
            fields[0].fieldGroup![4].fieldGroup?.splice(4, 2);
          }
          if (fields[0].fieldGroup![1].fieldGroup?.length === 4) {
            fields[0].fieldGroup![1].fieldGroup?.splice(1, 1);
          }
          this.legalFormControlSubscription?.unsubscribe();
        } else {
          setTimeout(() => {
            // get legal form formControl
            const legalFormControl =
              fields[0].fieldGroup?.[1].fieldGroup?.[0].fieldGroup?.[1].fieldGroup?.[1].form?.get(
                'companyForm_F60000339'
              );
            this.legalFormControlSubscription = legalFormControl?.valueChanges
              .pipe(startWith(legalFormControl?.value))
              .subscribe((selectedLegalForm) => {
                let clonedSubGroupFieldGroup: FormlyFieldConfig[] | undefined;
                // Erklärungen und Anlagen
                if (fields[0].fieldGroup![4].fieldGroup?.length === 8) {
                  clonedSubGroupFieldGroup =
                    fields[0].fieldGroup![4].fieldGroup;
                  // make subGroup immutable so that fieldGroups do not get lost when switching between legal forms
                  this.immutableSubGroupCommon = Object.freeze(
                    clonedSubGroupFieldGroup
                  );
                  fields[0].fieldGroup![4].fieldGroup =
                    clonedSubGroupFieldGroup;
                }
                // subGroup should always have complete fieldGroups
                fields[0].fieldGroup![4].fieldGroup = this.unfreezeFieldGroup(
                  this.immutableSubGroupCommon
                );

                // Angaben zum Unternehmen
                if (fields[0].fieldGroup![1].fieldGroup?.length === 4) {
                  clonedSubGroupFieldGroup =
                    fields[0].fieldGroup![1].fieldGroup;
                  // make subGroup immutable so that fieldGroups do not get lost when switching between legal forms
                  this.immutableSubGroupCompany = Object.freeze(
                    clonedSubGroupFieldGroup
                  );
                  fields[0].fieldGroup![1].fieldGroup =
                    clonedSubGroupFieldGroup;
                }
                fields[0].fieldGroup![1].fieldGroup = this.unfreezeFieldGroup(
                  this.immutableSubGroupCompany
                );

                if (
                  selectedLegalForm === null ||
                  (selectedLegalForm !==
                    LEGAL_FORM_SONSTIGE_GEBIETKORPERSCHAFT &&
                    selectedLegalForm !==
                      LEGAL_FORM_KORPERSCHAFT_OFFENTLICHES_RECHT)
                ) {
                  setTimeout(() => {
                    // remove sections that are not required for legal forms other than "Sonstige Gebietskörperschaft" and "Körperschaften des öffentlichen Rechts"
                    if (fields[0].fieldGroup![4].fieldGroup?.length === 8) {
                      fields[0].fieldGroup![4].fieldGroup?.splice(4, 2);
                    }
                  }, 10);
                }
                // Company
                if (
                  selectedLegalForm === null ||
                  (selectedLegalForm !== LEGAL_FORM_EHELEUTE &&
                    selectedLegalForm !==
                      LEGAL_FORM_SONSTIGE_PERSONENGESELLSCHAFTEN &&
                    selectedLegalForm !==
                      LEGAL_FORM_GESELLSCHAFT_DES_BUERGERLICHEN_RECHTS)
                ) {
                  // remove section 'Gemeinschaftliche Haftung'
                  if (fields[0].fieldGroup![1].fieldGroup?.length === 4) {
                    fields[0].fieldGroup![1].fieldGroup?.splice(1, 1);
                  }
                }
              });
          }, 10);
        }
      });
  }

  /**
   * Method dynamically renders list of loaded funding measures into the initialized form scheme
   * @param fields
   * @param fundingMeasures
   */
  public renderFundingMeasureList(
    fields: FormlyFieldConfig[],
    fundingMeasures: FundingMeasure[]
  ) {
    // navigation/page-group/page/sub-group/form-select path to measure in baseData
    fields[0].fieldGroup![0].fieldGroup![0].fieldGroup![0].fieldGroup![1][
      'hooks'
    ] = {
      onInit: () => {
        fields[0].fieldGroup![0].fieldGroup![0].fieldGroup![0].fieldGroup![1].props!.options =
          fundingMeasures;
      }
    };

    // navigation/page-group/page/sub-group/form-select (path to fundingMeasure in bankInformation.deviatingAccountHolder_G60000112)
    fields[0].fieldGroup![0].fieldGroup![3].fieldGroup![1].fieldGroup![2][
      'hooks'
    ] = {
      onInit: () => {
        fields[0].fieldGroup![0].fieldGroup![3].fieldGroup![1].fieldGroup![2].props!.options =
          fundingMeasures;
      }
    };
  }

  /**
   * Method dynamically renders list of loaded local area groups into the initialized form scheme
   * @param fields
   * @param lags
   * @param transformedItems
   */
  public renderLagList(
    fields: FormlyFieldConfig[],
    lags: LocalActionGroup[],
    transformedItems: MultiselectCheckboxData[]
  ) {
    // navigation/page-group/page/sub-group/form-select (path to lag in projectProfile
    fields[0].fieldGroup![2].fieldGroup![1].fieldGroup![0].fieldGroup![1][
      'hooks'
    ] = {
      onInit: () => {
        fields[0].fieldGroup![2].fieldGroup![1].fieldGroup![0].fieldGroup![1].props!.options =
          lags;
      }
    };

    // navigation/page-group/page/sub-group/form-select (path to furtherLAGsIfCooperationProjects in projectProfile
    fields[0].fieldGroup![2].fieldGroup![1].fieldGroup![0].fieldGroup![2][
      'hooks'
    ] = {
      onInit: () => {
        fields[0].fieldGroup![2].fieldGroup![1].fieldGroup![0].fieldGroup![2].props!.options =
          lags;
      }
    };

    fields[0].fieldGroup![2].fieldGroup![1].fieldGroup![0].fieldGroup![2][
      'expressions'
    ] = {
      'props.required':
        'field?.parent?.parent?.parent?.model?.measureAccordingToLeaderGuideline?.selectedMeasureAccordingToLeaderGuideline === "Kooperationsvorhaben auf Grundlage des regionalen Entwicklungskonzeptes" || formState.disabled',
      'model.furtherLAGsIfCooperationProjects': () => {
        return transformedItems;
      }
    };
  }

  /**
   * Method dynamically renders list of loaded nations into the initialized form scheme
   * @param fields
   * @param nations
   */
  public renderNationList(fields: FormlyFieldConfig[], nations: Nation[]) {
    // navigation/page-group/page/sub-group/form-select path to nation in baseData
    fields[0].fieldGroup![0].fieldGroup![1].fieldGroup![0].fieldGroup![8][
      'hooks'
    ] = {
      onInit: () => {
        fields[0].fieldGroup![0].fieldGroup![1].fieldGroup![0].fieldGroup![8].props!.options =
          nations;
      }
    };
  }

  /**
   * Method sets default values for the section "Vollmacht / Vertretungsberechtigung
   * @param fields list of form fields
   * @param projectRepresentatives authorized representatives (Vertreter)
   */
  prefillFormAttorneyPower(
    fields: FormlyFieldConfig[],
    projectRepresentatives: DeputyInfo[]
  ) {
    // set authorized representative deputies
    fields[0].fieldGroup![1].fieldGroup![2].fieldGroup![0].fieldGroup![1].defaultValue =
      projectRepresentatives.map((o) => {
        const attorneyPowerTypeTranslationKey = getAttorneyPowerTypes().find(
          (item) => item.id === o.attorneyPowerType
        )?.title;
        const attorneyPowerType = this.translateService.instant(
          attorneyPowerTypeTranslationKey ? attorneyPowerTypeTranslationKey : ''
        );
        return <FormProjectRepresentative>{
          lastname: o.user.nameLast,
          firstname: o.user.nameFirst,
          oamanAccessValidFrom: o.validFrom,
          oamanAccessValidTo: o.validTo,
          attorneyPowerType: attorneyPowerType,
          attorneyPowerStartDate: o.attorneyPowerStartDate,
          attorneyPowerEndDate: o.attorneyPowerEndDate,
          attorneyPowerAvailability:
            o.attorneyPowerAvailability.valueOf() === 1
              ? FormsAttorneyPowerTypeAvailability.Available
              : FormsAttorneyPowerTypeAvailability.AtHand
        };
      });
    return fields;
  }

  /**
   * Method sets default field values with provided data
   * @param fields list of form fields
   * @param currentUser currently logged-in user
   * @param fundingMeasure funding measure of the target funding project
   * @param currentFormTemplate
   * @param nation default value for nation
   */
  public prefillFormBaseData(
    fields: FormlyFieldConfig[],
    currentUser: User,
    fundingMeasure: FundingMeasure,
    nation: Nation | undefined,
    currentFormTemplate: string | undefined
  ): FormlyFieldConfig[] {
    // set default funding measure
    if (currentFormTemplate) {
      if (currentFormTemplate === FORM_TEMPLATE_ZILE) {
        fields[0].fieldGroup![0].fieldGroup![0].fieldGroup![0].fieldGroup![1].defaultValue =
          fundingMeasure.title;
      } else if (currentFormTemplate === FormConstants.FORM_TEMPLATE_LEADER) {
        fields[0].fieldGroup![2].fieldGroup![0].fieldGroup![0].fieldGroup![0].defaultValue =
          fundingMeasure.title;
      }
    }

    // set default lastname
    fields[0].fieldGroup![0].fieldGroup![1].fieldGroup![0].fieldGroup![0].defaultValue =
      currentUser.nameLast;

    if (currentUser.nameFirst !== currentUser.nameLast) {
      // set default firstname
      fields[0].fieldGroup![0].fieldGroup![1].fieldGroup![0].fieldGroup![1].defaultValue =
        currentUser.nameFirst;
    }

    // set default email
    fields[0].fieldGroup![0].fieldGroup![2].fieldGroup![0].fieldGroup![4].defaultValue =
      currentUser.email;

    // set default nation - Deutschland
    fields[0].fieldGroup![0].fieldGroup![1].fieldGroup![0].fieldGroup![8].defaultValue =
      nation?.title;
    return fields;
  }

  /**
   * Method creates a hook for a formly field
   * @param field field/fieldGroup to which the hook will be applied
   * @param subGroup to which the field/fieldGroup belong
   * @param fundingMeasureControl fundingMeasureControl Control element of selected FundingMeasure
   * @param fundingMeasures
   * @param options formOptions
   * @private
   */
  private createHook(
    field: FormlyFieldConfig,
    subGroup: FormlyFieldConfig,
    fundingMeasureControl: AbstractControl | null | undefined,
    fundingMeasures: string[],
    options: FormlyFormOptions
  ) {
    return {
      onInit: () => {
        fundingMeasureControl?.valueChanges
          .pipe(startWith(fundingMeasureControl?.value))
          .subscribe((selectedMeasure) => {
            let clonedSubGroupFieldGroup: FormlyFieldConfig[] | undefined;
            if (subGroup.parent?.parent?.fieldGroup?.length === 5) {
              clonedSubGroupFieldGroup = subGroup.parent?.parent?.fieldGroup;
              // make subGroup immutable so that fieldGroups do not get lost when switching between funding measures
              this.immutableSubGroupZile = Object.freeze(
                clonedSubGroupFieldGroup
              );
              subGroup.parent!.parent!.fieldGroup = clonedSubGroupFieldGroup;
            }
            // subGroup should always have complete fieldGroups
            subGroup.parent!.parent!.fieldGroup = this.unfreezeFieldGroup(
              this.immutableSubGroupZile
            );

            if (selectedMeasure) {
              field.expressions = {
                hide: `${!fundingMeasures.includes(selectedMeasure)}`
              };

              // this state value is used to hide and also to validate sections of the form
              options.formState.fundingMeasure = selectedMeasure;

              if (selectedMeasure === FUNDING_MEASURE_DORFENTWICKLUNGSPLAN) {
                setTimeout(() => {
                  // remove sections that are not required for the funding measure "Dorfentwicklungsplan" from the subGroup
                  if (subGroup.parent?.parent?.fieldGroup?.length !== 1) {
                    subGroup.parent?.parent?.fieldGroup?.splice(1, 4);
                  }
                }, 10);
              }
            } else {
              options.formState.fundingMeasure = null;
              field.expressions = { hide: 'false' };
            }
          });
      }
    };
  }

  /**
   * function to make an immutable fieldGroup mutable
   * @param fieldGroup
   * @private
   */
  private unfreezeFieldGroup(
    fieldGroup: Readonly<FormlyFieldConfig[] | undefined>
  ): FormlyFieldConfig[] {
    let oo: FormlyFieldConfig[] | undefined = undefined;
    oo = [];
    let clone = function (v: MutableFormlyFieldConfig<FormlyFieldConfig>) {
      oo?.push(v);
    };
    fieldGroup?.forEach(clone);
    return oo;
  }
}

/**
 * Util type to make object properties not readonly
 */
type MutableFormlyFieldConfig<FormlyFieldConfig> = {
  -readonly [Value in keyof FormlyFieldConfig]: FormlyFieldConfig[Value];
};
