import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TotalCostFieldObserverService {
  private dataSubject: Subject<boolean> = new Subject<boolean>();
  public data$: Observable<boolean> = this.dataSubject.asObservable();

  constructor() {}

  updateData(newData: boolean): void {
    this.dataSubject.next(newData);
  }
}
