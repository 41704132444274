import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TooltipPosition } from '@angular/material/tooltip';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../../../dialog/services/dialog.service';
import {
  FundingProjectDocument,
  FundingProjectDocumentWithAppProperties
} from '../interfaces/funding-project-document';
import { ProjectActionService } from '../../../services/project-action.service';
import { DeleteDialogData } from '../../../../../dialog/interfaces/delete-dialog-date';
import { FilterObservableService } from '../services/filter-observable.service';
import { DocumentFilterPipe } from '../pipes/document-filter.pipe';
import { APP_CONFIG } from '../../../../../core/util/app-config.token';
import { AppConfiguration } from '../../../../../core/interfaces/app-configuration';
import { UserObjectObservableService } from '../../../../../core/services/user-object-observable.service';
import { MailSendingConfigDto } from '../../../../../core/interfaces/user';
import { ProjectActionData } from '../../../interfaces/project-action';
import { MatMenuTrigger } from '@angular/material/menu';
import { FundingProject } from '../../../../../core/interfaces/funding-project';
import { FundingProjectRequest } from '../../../../../core/interfaces/funding-project-request';
import { FilterObject } from '../interfaces/filter-object';
import { find as _find, get as _get } from 'lodash';
import {
  DocumentAssignProjectActionData,
  FundingProjectDataService
} from '../../../../public-api';
import * as FundingProjectStates from '../../../utils/fundingProjectStates';
import { AccountTypes } from '../../../../../core/constants/account-types.constants';
import {
  isAntrag,
  isAntragsformulare,
  isDeliveryEnabled,
  isDocumentCreatedByApplicant,
  isEmptyDocument,
  isForUnsubmittedRequest,
  isProcessedDocument
} from '../utils/document-utils';
import { AssignedRequest } from '../../../interfaces/assigned-request';
import { BaseComponent } from '../../../../../core/base.component';
import { isProjectModifiable } from '../../../../../core/util/funding-project-utils';
import { DocAndFileTitlePipeParams } from '../interfaces/document-and-file-title.pipe-data';
import { AppPropertiesDTO } from '../../../../../core/config/base-app-config.dt';

@Component({
  selector: 'oaman-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
  providers: [DocumentFilterPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsTableComponent
  extends BaseComponent
  implements OnDestroy, AfterViewInit
{
  deliveryConfig: MailSendingConfigDto = {
    enabled: false,
    accountType: undefined
  };
  dataSource = new MatTableDataSource<FundingProjectDocument>();
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  @ViewChild('matMenuTrigger')
  matMenuTrigger!: MatMenuTrigger;
  isDraft = true;
  withdrawnProject = false;
  fundingGuidelineId!: string;
  fundingProject!: FundingProject;
  navigationSubscription: Subscription;
  filterObservableSubscription!: Subscription;
  enrollApp = false;
  manageApp = false;
  connectorType = '';
  toolTipPosition: TooltipPosition = 'left';
  tableType = 'documents';
  documents: FundingProjectDocument[] = [];
  displayedColumns: Set<string> = new Set([
    'description',
    'title',
    'docType',
    'submitted',
    'requestDeadline',
    'files'
  ]);
  allRequests!: FundingProjectRequest[];
  docAndFileTitlePipeParams!: DocAndFileTitlePipeParams;
  appProperties!: AppPropertiesDTO;
  documentWithAppProperties!: FundingProjectDocumentWithAppProperties;
  constructor(
    @Inject(APP_CONFIG) public appConfig: AppConfiguration,
    public dialogService: DialogService,
    public projectActionService: ProjectActionService,
    public documentFilterPipe: DocumentFilterPipe,
    public filterObservableService: FilterObservableService,
    public router: Router,
    public route: ActivatedRoute,
    public changeDetector: ChangeDetectorRef,
    public userObjectObservableService: UserObjectObservableService,
    public fundingProjectData: FundingProjectDataService
  ) {
    super(userObjectObservableService);
    this.enrollApp = this.appConfig.context === 'enroll';
    this.manageApp = this.appConfig.context === 'manage';
    this.route.data.subscribe((data) => {
      this.appProperties = _get(data, 'resolvedData.appProperties');
    });
    this.connectorType = this.appProperties?.connectorType;
    this.docAndFileTitlePipeParams = {
      appContext: this.appConfig?.context,
      connectorType: this.connectorType
    };

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initialise the component
      if (e.routerEvent instanceof NavigationEnd) {
        this.initializeDocuments();
      }
    });
  }

  ngAfterViewInit() {
    // set the default filter for the initial view, displaying the newest request for the user
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.navigationSubscription?.unsubscribe();
    this.filterObservableSubscription?.unsubscribe();
  }

  initializeDocuments() {
    this.route.data.subscribe((data) => {
      this.documents = _get(data, 'resolvedData.documents');
      this.allRequests = _get(data, 'resolvedData.requests');
      // check if the Project is still in the draft status for conditional column rendering
      if (this.route.parent?.parent) {
        this.route.parent.parent?.data.subscribe((fundingProject) => {
          const resolvedData: FundingProject = fundingProject['resolvedData'];
          this.isDraft =
            resolvedData.stateFundingProject.resourceKey ===
            FundingProjectStates.DRAFT;
          this.withdrawnProject =
            resolvedData.stateFundingProject.resourceKey ===
            FundingProjectStates.WITHDRAWN;
          this.fundingProject = resolvedData;
          this.fundingGuidelineId = resolvedData.fundingGuideline.id;

          this.onFundingProjectLoad();
          // add the "action" column conditionally
          if (
            isProjectModifiable(
              this.fundingProject,
              this.currentUser,
              this.manageApp
            )
          ) {
            this.displayedColumns.add('action');
          }
        });
      }
      // set the default filter for the initial view, displaying the newest request for the user
      this.filterObservableSubscription =
        this.filterObservableService.filterObject$.subscribe((filter) => {
          this.onFilterChanged(filter);
        });
    });
  }

  onFundingProjectLoad(): void {
    // used only to trigger custom actions after the funding project is loaded
  }

  onFilterChanged(filters: FilterObject) {
    this.initTable(this.documentFilterPipe.transform(this.documents, filters));
  }
  initTable(data: FundingProjectDocument[]) {
    this.dataSource = new MatTableDataSource([...data]);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'title':
          return item.title;
        case 'submitted':
          if (item.deliveryDate) {
            return item.deliveryDate;
          }
          return item.checkInDate ? item.checkInDate : '';
        case 'requestDeadline':
          return item.requestedDeadline ? item.requestedDeadline : '';
        case 'files':
          return item.submitted === item.total || item.submitted === 0
            ? `${item.total}`
            : `${item.submitted}/${item.total}`;
        default: {
          console.error('error in sorting data accessor');
          return item.title;
        }
      }
    };
    // initializes the paginator and sort again after the content has changed
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.changeDetector.detectChanges();
  }

  getDropdownTooltip(element: FundingProjectDocument, context: string): string {
    if (this.hasMissingActionAuthority(element, context)) {
      return 'fundingProject.details.missingProjectAuthority';
    }

    switch (context) {
      case 'uploadFile':
        return this.getUploadFileTooltip(element);
      case 'submit':
        return this.getSubmitTooltip(element);
      case 'edit':
        return this.getEditTooltip(element);
      case 'delete':
        return this.getDeleteTooltip(element);
      case 'deliver':
        return this.getDeliveryTooltip(element, context);
      case 'assignDocument':
        return this.getAssignDocumentTooltip();
      default:
        return '';
    }
  }

  isButtonDisabled(element: FundingProjectDocument, context: string): boolean {
    if (element.isTempDocument && context !== 'assignDocument') {
      // disable all actions for temp document, except the request assignment
      return true;
    }
    switch (context) {
      case 'uploadFile':
        return this.isUploadDisabled(element, context);
      case 'submit':
        return this.isSubmitDisabled(element);
      case 'deliver':
        return this.isDeliveryDisabled(element);
      case 'edit':
        return this.isEditionDisabled(element);
      case 'delete':
        return this.isDeleteDisabled(element);
      case 'assignDocument':
        return this.isRequestAssignmentDisabled(element, context);
      default:
        return false;
    }
  }

  isAssignDocumentVisible(element: FundingProjectDocument): boolean {
    return !element.requestAndType || element.isTempDocument;
  }

  openDialog(document: FundingProjectDocument, context: string) {
    const submitOrDeleteData: ProjectActionData = {
      fundingProjectId: this.fundingProject.id,
      fundingGuidelineId: this.fundingGuidelineId,
      title: document.title,
      documentId: document.id
    };

    if (context === 'submitDocument') {
      this.dialogService
        .openDocumentSubmitDialog(document.title)
        .subscribe((result) => {
          // send the submit request if the user confirms
          if (result) {
            this.projectActionService.setAction('submit', submitOrDeleteData);
          }
          return result;
        });
    } else if (context === 'editDocument') {
      if (this.enrollApp) {
        this.dialogService
          .openCreateDocumentDialog(document)
          .subscribe((result) => {
            // send the edit request if the user confirms
            if (result) {
              result.documentId = document.id;
              result.fundingGuidelineId = this.fundingGuidelineId;
              result.fundingProjectId = this.fundingProject.id;
              this.projectActionService.setAction('edit', result);
            }
            return result;
          });
      } else {
        this.dialogService
          .openRequestDocumentDialog(document)
          .subscribe((result) => {
            // send the edit request if the user confirms
            if (result) {
              result.data.documentId = document.id;
              result.data.fundingGuidelineId = this.fundingGuidelineId;
              result.data.fundingProjectId = this.fundingProject.id;
              this.projectActionService.setAction('requestEdit', result.data);
            }
            return result;
          });
      }
    } else if (context === 'deleteDocument') {
      const deleteDialogData: DeleteDialogData = {
        title: document.title,
        deleteTitle: 'fundingProject.details.documents.dialog.deleteTitle',
        deleteTxt1: 'fundingProject.details.documents.dialog.deleteTxt1',
        deleteTxt2: 'fundingProject.details.documents.dialog.deleteTxt2'
      };
      this.dialogService
        .openDeleteDialog(deleteDialogData)
        .subscribe((result) => {
          // send the delete request if the user confirms
          if (result) {
            this.projectActionService.setAction('delete', submitOrDeleteData);
          }
          return result;
        });
    } else if (context === 'uploadFile') {
      this.dialogService
        .openUploadDialog(document.title, this.fundingProject.id, document.id)
        .subscribe((dialogResult) => {
          if (dialogResult) {
            // append document id to formdata
            dialogResult.data.append(
              'applicantDocumentId',
              new Blob([JSON.stringify(document.id)], {
                type: 'application/json'
              })
            );

            const fileUploadData: ProjectActionData = {
              fundingProjectId: this.fundingProject.id,
              fundingGuidelineId: this.fundingGuidelineId,
              documentId: document.id,
              fileUploadData: dialogResult.data
            };
            this.projectActionService.setAction('uploadFile', fileUploadData);
          }
        });
    } else if (context === 'deliverDocument') {
      this.openDocumentDeliverDialog(document);
    } else if (context === 'assignDocument') {
      this.openDocumentAssignDialog(document);
    }
  }

  openInfoDialog(e: MouseEvent, element: FundingProjectDocument) {
    e.stopPropagation();
    this.documentWithAppProperties = {
      fundingProjectDocument: element,
      connectorType: this.appProperties?.connectorType
    };
    this.dialogService.openInfoDocumentDialog(this.documentWithAppProperties);
  }

  getFile(document: FundingProjectDocument) {
    if (!document.submitted && !document.total) {
      return 0;
    }

    return document.submitted === document.total || document.submitted === 0
      ? `${document.total}`
      : `${document.submitted}/${document.total}`;
  }

  showFiles(document: FundingProjectDocument) {
    this.router.navigate([document.id], { relativeTo: this.route }).then();
  }

  onKeyupEnter($event: any) {
    $event.stopPropagation();
    this.matMenuTrigger.openMenu();
  }

  private openDocumentDeliverDialog(document: FundingProjectDocument): void {
    this.projectActionService.setAction('displayDeliverDialog', {
      fundingGuidelineId: this.fundingGuidelineId,
      fundingProjectId: this.fundingProject.id,
      title: this.fundingProject.title,
      documentId: document.id,
      documentEAkteId: document.eAkteId,
      updateProjectActionData: document,
      fundingMeasureDTO: this.fundingProject.fundingMeasureDTO,
      requestSubmissionDate: this.getRequestSubmissionDate(
        document.requestAndType
      ),
      connectorType: this.appProperties?.connectorType,
      appContext: this.appConfig?.context
    });
  }

  private openDocumentAssignDialog(document: FundingProjectDocument): void {
    const assignDocumentData: DocumentAssignProjectActionData = {
      fundingProjectId: this.fundingProject.id,
      fundingGuidelineId: this.fundingGuidelineId,
      availableRequests: this.allRequests,
      documentToAssign: document
    };
    this.projectActionService.setAction(
      'assignDocumentToRequest',
      assignDocumentData
    );
  }

  showWarningIcon(element: FundingProjectDocument): boolean {
    if (!element.documentType.submissionEnabled) {
      return false;
    }
    if (
      !isProcessedDocument(element) ||
      (element.isTempDocument &&
        !isAntragsformulare(element, this.connectorType))
    ) {
      return true;
    }
    if (
      !element.deliveryDate &&
      (element.submitted < element.total ||
        (element.submitted == 0 && element.total != 0))
    ) {
      return true;
    }
    return false;
  }

  getWarningIconTooltip(element: FundingProjectDocument): string {
    const msgPrefix = 'fundingProject.details.documents.table.';
    if (element.isTempDocument) {
      return msgPrefix + 'notAssignedToRequest';
    }
    return msgPrefix + 'missingDocumentTooltip';
  }

  ///////////////// Private functions ///////////////////

  private getDeleteTooltip(element: FundingProjectDocument): string {
    const msgPrefix =
      'fundingProject.details.documents.table.dropdown.tooltip.delete.';
    if (element.checkInDate) {
      return msgPrefix + 'submittedOderPartial';
    } else if (element.deliveryDate) {
      return msgPrefix + 'deliveredByClerk';
    }
    return msgPrefix + 'requestedFromClerk';
  }

  private getEditTooltip(element: FundingProjectDocument): string {
    const msgPrefix =
      'fundingProject.details.documents.table.dropdown.tooltip.edit.';
    if (element.checkInDate) {
      return msgPrefix + 'submittedOderPartial';
    }
    if (element.deliveryDate) {
      return msgPrefix + 'deliveredByClerk';
    }
    if (this.manageApp && element.requestedDate) {
      return msgPrefix + 'disabledRequested';
    }

    return this.manageApp
      ? msgPrefix + 'disabledForClerk'
      : msgPrefix + 'requestedFromClerk';
  }

  private getDeliveryTooltip(
    element: FundingProjectDocument,
    context: string
  ): string {
    const msgPrefix =
      'fundingProject.details.documents.table.dropdown.tooltip.deliver.';
    if (!this.deliveryConfig?.enabled) {
      if (this.deliveryConfig?.accountType === AccountTypes.BUNDID_ACCOUNT) {
        return msgPrefix + 'bundIDKontoDisabled';
      }
      return this.isProjectByServiceAccountUser()
        ? msgPrefix + 'serviceKontoDisabled'
        : msgPrefix + 'unternehmensKontoDisbled';
    }
    if (this.isButtonDisabled(element, context)) {
      if (element.isTempDocument) {
        return msgPrefix + 'notAssignedToRequest';
      }
      if (isEmptyDocument(element)) {
        return msgPrefix + 'requestedFromClerk';
      }
      if (element.checkInDate) {
        return msgPrefix + 'submitted';
      }
      if (element.deliveryDate) {
        return msgPrefix + 'delivered';
      }
      if (!isDeliveryEnabled(element)) {
        return msgPrefix + 'notSubmittable';
      }
      return msgPrefix + 'disabled';
    } else if (element.total > 0) {
      return msgPrefix + 'onAction';
    }
    return '';
  }

  private getUploadFileTooltip(element: FundingProjectDocument): string {
    const msgPrefix =
      'fundingProject.details.documents.table.dropdown.tooltip.fileUpload.';
    if (element.deliveryDate) {
      return msgPrefix + 'deliveredByClerk';
    }
    if (element.checkInDate) {
      if (this.manageApp) {
        return msgPrefix + 'submitedByApplicant';
      }
      return msgPrefix + 'submited';
    }
    return '';
  }

  private getSubmitTooltip(element: FundingProjectDocument): string {
    const msgPrefix =
      'fundingProject.details.documents.table.dropdown.tooltip.submit.';
    if (element.total > 0 && isForUnsubmittedRequest(element)) {
      return msgPrefix + 'reqNotSubmitted';
    }
    if (isEmptyDocument(element)) {
      return msgPrefix + 'emptyDocument';
    }
    if (element.checkInDate) {
      return msgPrefix + 'submitted';
    }
    if (element.deliveryDate) {
      return msgPrefix + 'deliveredByClerk';
    }
    return '';
  }

  private getAssignDocumentTooltip(): string {
    return 'fundingProject.details.documents.table.dropdown.tooltip.assignDocument';
  }

  private isProjectByServiceAccountUser(): boolean {
    return this.deliveryConfig?.accountType === AccountTypes.SERVICE_ACCOUNT;
  }

  private hasMissingActionAuthority(
    element: FundingProjectDocument,
    context: string
  ): boolean {
    if (['uploadFile', 'submit', 'delete', 'deliver'].includes(context)) {
      const hasAuthority = this.fundingProjectData.hasProjectAuthority(context);
      if (
        !hasAuthority &&
        ((context !== 'deliver' &&
          !this.fundingProjectData.isCreatedByCurrentUser(
            element.author?.id
          )) ||
          context === 'deliver')
      ) {
        return true;
      }
    }

    return false;
  }

  private isDeleteDisabled(element: FundingProjectDocument): boolean {
    return (
      this.manageApp ||
      isProcessedDocument(element) ||
      !isDocumentCreatedByApplicant(element) ||
      !this.isDocumentActionAuthorized(element, 'deleteDocument') ||
      isAntrag(element)
    );
  }

  private isEditionDisabled(element: FundingProjectDocument): boolean {
    return (
      this.manageApp ||
      (this.enrollApp && !isDocumentCreatedByApplicant(element)) ||
      isProcessedDocument(element) ||
      !this.isDocumentActionAuthorized(element, 'edit') ||
      isAntrag(element)
    );
  }

  private isDeliveryDisabled(element: FundingProjectDocument): boolean {
    if (
      !this.deliveryConfig?.enabled ||
      isEmptyDocument(element) ||
      isProcessedDocument(element) ||
      !this.fundingProjectData.hasProjectAuthority('deliverDocument') ||
      element.title === 'Antragsformulare' ||
      !isDeliveryEnabled(element)
    ) {
      return true;
    }
    return false;
  }

  private isSubmitDisabled(element: FundingProjectDocument): boolean {
    if (
      isForUnsubmittedRequest(element) ||
      isEmptyDocument(element) ||
      isProcessedDocument(element) ||
      !this.isDocumentActionAuthorized(element, 'submitDocument') ||
      isAntrag(element)
    ) {
      return true;
    }
    return false;
  }

  private isUploadDisabled(
    element: FundingProjectDocument,
    context: string
  ): boolean {
    return (
      isProcessedDocument(element) ||
      !this.isDocumentActionAuthorized(element, context) ||
      isAntrag(element)
    );
  }

  isRequestAssignmentDisabled(
    element: FundingProjectDocument,
    context: string
  ) {
    return (
      isAntragsformulare(element, this.connectorType) &&
      context === 'assignDocument'
    );
  }

  /**
   * Checks if the given action is authorized for the currently logged in user:<br>
   * - the user must be the creator of the document
   * - or the user must be a valid deputy of the current funding project
   * @param element
   * @param actionKey
   * @returns
   */
  private isDocumentActionAuthorized(
    element: FundingProjectDocument,
    actionKey: string
  ): boolean {
    return (
      this.fundingProjectData.hasProjectAuthority(actionKey) ||
      this.fundingProjectData.isCreatedByCurrentUser(element.author?.id)
    );
  }

  private getRequestSubmissionDate(
    documentRequest: AssignedRequest
  ): string | undefined {
    const request = _find(this.allRequests, ['id', documentRequest.id]);

    return request?.submissionDate;
  }
}
