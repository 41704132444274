import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MultiSelectTransformDataService {
  // Transform and Save OutPut
  transformDataBeforeSave(lagItems: any[]) {
    //let lagItems = this.model['projectProfile'].furtherLAGsIfCooperationProjects;
    if (lagItems !== null) {
      // We need to manipulate it if the Array contains objects and not strings.
      let changeNeeded =
        lagItems?.length > 0 &&
        !lagItems.every((item: any) => typeof item === 'string');
      if (changeNeeded) {
        //this.model['projectProfile'].furtherLAGsIfCooperationProjects =
        return lagItems.map((elem: { lagId: string }) => elem.lagId);
      }
    }
    return [];
  }
}
