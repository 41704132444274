<div class="table-wrapper">
  <p class="breadcrumb">
    <a
      tabindex="0"
      role="button"
      (click)="navigateToDocuments()"
      (keyup.enter)="navigateToDocuments()"
      >{{
        'fundingProject.details.documents.fileTable.documents' | translate
      }}</a
    >
    ->
    <strong>{{documentTitle | documentAndFileTitlePipe: docAndFileTitlePipeParams }}</strong>
  </p>
  <mat-table id="documents-table" [dataSource]="dataSource" matSort>
    <!-- Hint Column -->
    <ng-container matColumnDef="description">
      <mat-header-cell class="first-column" *matHeaderCellDef>
      </mat-header-cell>
      <!-- Show icon if the document is missing -->
      <mat-cell class="first-column" *matCellDef="let element">
        <mat-icon
          *ngIf="
            documentSubmissionEnabled &&
            !element.submitted &&
            !element.deliveryDate
          "
          matTooltip="{{
            'fundingProject.details.documents.fileTable.missingFileTooltip'
              | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.documents.fileTable.missingFileTooltip'
              | translate
          }}"
          class="highlighted"
          >report_problem
        </mat-icon>
      </mat-cell>
    </ng-container>
    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.fileTable.a11y.title' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.fileTable.title' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span
          class="text-fit-ellipsis"
          matTooltip="{{ element.title }}"
          matTooltipClass="long-title-tooltip"
          >{{ element.title }}</span
        >
      </mat-cell>
    </ng-container>

    <!-- Typ Column -->
    <ng-container matColumnDef="docType">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.fileTable.a11y.documentType' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.fileTable.documentType' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.typeApplicantDocument?.title }} </mat-cell>
    </ng-container>

    <!-- Author Column -->
    <ng-container matColumnDef="author">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.fileTable.a11y.author' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.fileTable.author' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.author }} </mat-cell>
    </ng-container>

    <!-- uploaded Column -->
    <ng-container matColumnDef="uploaded">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.fileTable.a11y.uploaded' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.fileTable.uploaded' | translate }}
      </mat-header-cell>
      <!-- Highlighted if the document has been requested and has a deadline -->
      <mat-cell *matCellDef="let element">
        {{ element.uploaded | date }}
      </mat-cell>
    </ng-container>

    <!-- submitted Column -->
    <ng-container matColumnDef="submitted">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.fileTable.a11y.submitted'
            | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.fileTable.submitted' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{
          (element.submitted ? element.submitted : element.deliveryDate) | date
        }}
      </mat-cell>
    </ng-container>

    <!-- fileType Column -->
    <ng-container matColumnDef="fileType">
      <mat-header-cell
        [title]="
          'fundingProject.details.documents.fileTable.a11y.fileType' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.details.documents.fileTable.fileType' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.fileType }} </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action" *ngIf="!withdrawnProject">
      <mat-header-cell class="last-column" *matHeaderCellDef> </mat-header-cell>
      <mat-cell class="last-column" *matCellDef="let element">
        <mat-icon
          #matMenuTrigger="matMenuTrigger"
          (keyup.enter)="onKeyupEnter()"
          [matMenuTriggerFor]="menu"
          role="button"
          tabindex="0"
          matTooltip="{{
            'fundingProject.details.documents.fileTable.more' | translate
          }}"
          attr.aria-label="{{
            'fundingProject.details.documents.fileTable.more' | translate
          }}"
          class="action-icon"
          *ngIf="element"
          >more_horiz
        </mat-icon>
        <mat-menu #menu="matMenu" xPosition="before">
          <div>
            <button
              mat-menu-item
              class="dropdown-item"
              (click)="openFile(element)"
            >
              {{
                'fundingProject.details.documents.fileTable.dropdown.openFile'
                  | translate
              }}
            </button>
          </div>
          <div
            *ngIf="isButtonVisible(element, 'deleteFile')"
            matTooltip="{{
              getDropdownTooltip(element, 'deleteFile') | translate
            }}"
            [matTooltipDisabled]="!isButtonDisabled(element, 'deleteFile')"
          >
            <button
              mat-menu-item
              class="dropdown-item"
              (click)="openDialog(element, 'deleteFile')"
              [disabled]="isButtonDisabled(element, 'deleteFile')"
            >
              {{
                'fundingProject.details.documents.fileTable.dropdown.deleteFile'
                  | translate
              }}
            </button>
          </div>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row
      id="header-row"
      *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    id="paginator"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</div>
