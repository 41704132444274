import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { TotalCostFieldObserverService } from '../../services/total-cost-field-observer.service';
import {
  TOTAL_COSTS_REQUESTED,
  TOTAL_COSTS_REQUESTED_INPUT_FIELD_ERROR,
  TOTAL_COSTS_REVENUE_TFE,
  TOTAL_COSTS_REVENUE_TFE_INPUT_FIELD_ERROR
} from '../../utils/forms-constants';

@Component({
  selector: 'oaman-form-cost-calculation-financial-plan-type',
  template: `
    <div class="wrapper">
      <div class="label-container">
        <ng-container
          *ngIf="
            props.disabled && isTotalCostsField && showErrorMsg;
            else elseBlock
          "
        >
          <span class="asterisk" [innerHTML]="props.label"></span>
          <span class="asterisk">*</span>
        </ng-container>
        <ng-template #elseBlock>
          <span [innerHTML]="props.label"></span>
        </ng-template>

        <ng-container
          *ngIf="props.required && props.hideRequiredMarker !== true"
        >
          <span class="asterisk">*</span>
        </ng-container>
      </div>

      <div class="fields-container">
        <div class="field-item" *ngFor="let field of field.fieldGroup">
          <formly-field [field]="field"></formly-field>

          <ng-container *ngIf="field.props.disabled">
            <span class="asterisk" [hidden]="!showErrorMsg">{{
              errorMessage
            }}</span>
          </ng-container>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .wrapper {
        margin-bottom: 2rem;
        word-break: break-word;
        width: 100%; /* Ensure wrapper spans full width */
      }
      .label-container {
        margin-bottom: 0.5rem; /* Add space between label and input fields */
        width: 100%;
      }
      .fields-container {
        width: 100%;
      }
      .field-item {
        width: 100%; /* Ensure each field item spans full width */
      }
      .field-item formly-field {
        width: 100%; /* Ensure each formly field spans full width */
      }
    `
  ]
})
export class FormCostCalculationFinancialPlanTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  showErrorMsg!: boolean;
  isTotalCostsField!: boolean;
  errorMessage!: string;

  constructor(
    private totalCostFieldObserverService: TotalCostFieldObserverService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.props.disabled) {
      if (this.field.key === TOTAL_COSTS_REQUESTED) {
        this.errorMessage = TOTAL_COSTS_REQUESTED_INPUT_FIELD_ERROR;
      }
      if (this.field.key === TOTAL_COSTS_REVENUE_TFE) {
        this.errorMessage = TOTAL_COSTS_REVENUE_TFE_INPUT_FIELD_ERROR;
      }

      this.isTotalCostsField = this.checkForTotalCostsFieldKey(this.field.key);

      this.totalCostFieldObserverService.data$.subscribe((sameValue) => {
        this.showErrorMsg = !sameValue;
        this.isTotalCostsField = this.checkForTotalCostsFieldKey(
          this.field.key
        );
      });
    }
  }

  checkForTotalCostsFieldKey(fieldKey: any): boolean {
    return (
      fieldKey === TOTAL_COSTS_REQUESTED || fieldKey === TOTAL_COSTS_REVENUE_TFE
    );
  }
}
