import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MultiselectCheckboxData } from '../../interfaces/multiselect-checkbox-data';

@Component({
  selector: 'oaman-form-multi-select-dropdown-type',
  template: `
    <ng-multiselect-dropdown
      [formControl]="formControl"
      [placeholder]="'- Bitte auswählen -'"
      [settings]="dropdownSettings"
      [disabled]="props.disabled"
      [data]="dropDownData"
    >
    </ng-multiselect-dropdown>
  `,
  styles: [``]
})
export class FormNgMultiSelectDropdownComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  dropdownSettings: IDropdownSettings = {};
  dropDownData: MultiselectCheckboxData[] = [];

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'lagId',
      textField: 'title',
      enableCheckAll: false,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      noDataAvailablePlaceholderText: 'Keine Daten verfügbar.',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    //checking for duplicates
    let checkList: string[] = [];
    this.props.options?.forEach((elem) => {
      if (!checkList.includes(elem.lagId)) {
        checkList.push(elem.lagId);
        this.dropDownData.push(elem);
      }
    });
    //Sort the data alphabetically before displaying in the UI.
    this.dropDownData = this.dropDownData.sort(
      (a: MultiselectCheckboxData, b: MultiselectCheckboxData) =>
        a.title.localeCompare(b.title)
    );
  }
}
