<h1 mat-dialog-title>
  {{
    'fundingProject.details.documents.dialog.documentInfo.titleRequested'
      | translate
  }}
</h1>
<mat-dialog-content class="mat-typography">
  <p *ngIf="isRequested">
    {{
      'fundingProject.details.documents.dialog.documentInfo.descriptionRequested'
        | translate
    }}
  </p>
  <p *ngIf="isEnroll && isRequested">
    {{
      'fundingProject.details.documents.dialog.documentInfo.descriptionRequestedAdditional'
        | translate
    }}
  </p>
  <br />
  <h2>
    {{
      'fundingProject.details.documents.dialog.documentInfo.titleDocument'
        | translate
    }}
  </h2>
  <p>{{ checkForTitle(document.title) }}</p>


  <!-- Neu hinzugefügte Zeile für den Autor mit Sachbearbeiter:in -->
  <p class="sub-text" *ngIf="document.author">
    {{ 'Erstellt von' }}: {{ document.author.nameLast + ', ' + document.author.nameFirst }}
    <span *ngIf="getRoleTranslation(document.author.role)">
     ({{ getRoleTranslation(document.author.role) | translate }})
    </span>
  </p>

  <div *ngIf="document.description">
    <h2>
      {{
        'fundingProject.details.documents.dialog.documentInfo.descriptionDocument'
          | translate
      }}
    </h2>
    <p>{{ document.description }}</p>
    <br />
  </div>
  <p class="sub-text" *ngIf="document.requestedDeadline">
    {{
      'fundingProject.details.documents.dialog.documentInfo.requestDeadlineText'
        | translate
    }}
    {{ document.requestedDeadline | date }}
  </p>
  <!-- Neu hinzugefügte Zeile für die Person die das Dokument zugestellt/eingereicht hat -->
  <p class="sub-text" *ngIf="document.checkedInBy || document.deliveryBy">
    {{ 'Eingereicht von' }}:
    {{
      (document.checkedInBy ? document.checkedInBy.nameLast + ', ' + document.checkedInBy.nameFirst
        : document.deliveryBy.nameLast + ', ' + document.deliveryBy.nameFirst)
    }}
    <span *ngIf="getRoleTranslation(document.checkedInBy ? document.checkedInBy.role : document.deliveryBy.role)">
    ({{ getRoleTranslation(document.checkedInBy ? document.checkedInBy.role : document.deliveryBy.role) | translate }})
  </span>
  </p>
  <p class="sub-text" *ngIf="document.checkInDate || document.deliveryDate">
    {{
      'fundingProject.details.documents.dialog.documentInfo.submittedText'
        | translate
    }}
    {{ (document.checkInDate  ? document.checkInDate : document.deliveryDate) | date }}
  </p>

  <p class="sub-text" *ngIf="document.requestedDate">
    {{
      'fundingProject.details.documents.dialog.documentInfo.requestedBy'
        | translate
    }}
    {{ document.author.fullName }}
    {{ 'fundingProject.details.documents.dialog.documentInfo.on' | translate }}
    {{ document.requestedDate | date }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <oaman-button
    mat-button
    color="primary"
    [mat-dialog-close]="true"
  >
    {{
      'fundingProject.details.documents.dialog.buttons.cancelBtn2' | translate
    }}
  </oaman-button>
</mat-dialog-actions>
