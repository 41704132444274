<div [style.padding]="'1rem'">
  <div class="project-create-wrapper" fxFlex="50">
    <h1 [style.margin-bottom]="'4rem'">{{'fundingProject.create.header' | translate}}</h1>
    <form [formGroup]="fundingProjectForm">
      <div class="form-field">
        <div class="label required">
          <label for="title">
            {{ 'fundingProject.fields.title' | translate }}
          </label>
        </div>
        <input id="title" type="text" formControlName="title" required>
        <ng-container *ngTemplateOutlet="validationMessages; context: { $implicit: 'title' }"></ng-container>
      </div>

      <div class="form-field">
        <div class="label required">
          <label for="fundingGuideline">
            {{ 'fundingProject.fields.guidelines' | translate }}
          </label>
        </div>
        <input type="text"
          id="fundingGuideline"
          formControlName="fundingGuidelineId"
          placeholder="{{ 'fundingProject.fields.pleaseSelect' | translate }}"
               [matAutocomplete]="fgAuto" required>
        <mat-autocomplete #fgAuto="matAutocomplete" [displayWith]="getFundingGuidelineTitle.bind(this)">
          <mat-option *ngFor="let fundingGuideline of (filteredFundingGuidelines$ | async)"
                      [value]="fundingGuideline.id">
            {{ fundingGuideline.title }}
          </mat-option>
        </mat-autocomplete>
        <ng-container
          *ngTemplateOutlet="validationMessages; context: { $implicit: 'fundingGuidelineId' }"></ng-container>
      </div>

      <div class="form-field"
           *ngIf="fundingMeasures && fundingMeasures.length > 0 && fundingProjectForm.get('fundingGuidelineId')?.valid">
        <div class="label required">
          <label for="fundingMeasure">
            {{ 'fundingProject.fields.measure' | translate }}
          </label>
        </div>
        <input type="text"
          id="fundingMeasure"
          formControlName="fundingMeasureId"
          placeholder="{{ 'fundingProject.fields.pleaseSelect' | translate }}"
               [matAutocomplete]="fmAuto" required>
        <mat-autocomplete #fmAuto="matAutocomplete" [displayWith]="getFundingMeasureTitle.bind(this)">
          <mat-option *ngFor="let fundingMeasure of (filteredFundingMeasures$ | async)" [value]="fundingMeasure.id">
            {{ fundingMeasure.title }}
          </mat-option>
        </mat-autocomplete>
        <ng-container *ngTemplateOutlet="validationMessages; context: { $implicit: 'fundingMeasureId' }"></ng-container>
      </div>

      <div class="form-field"
           *ngIf="locations && locations.length > 0 && fundingProjectForm.get('fundingGuidelineId')?.valid && fundingProjectForm.get('fundingMeasureId')?.valid">
        <div class="label required">
          <label for="location">
            {{ 'fundingProject.fields.location' | translate }}
          </label>
        </div>
        <input type="text"
          id="location"
          formControlName="locationId"
          placeholder="{{ 'fundingProject.fields.pleaseSelect' | translate }}"
               [matAutocomplete]="lAuto" required>
        <mat-autocomplete #lAuto="matAutocomplete" [displayWith]="getLocationTitle.bind(this)">
          <mat-option *ngFor="let location of (filteredLocations$ | async)" [value]="location.id">
            {{ location.title }}
          </mat-option>
        </mat-autocomplete>
        <ng-container *ngTemplateOutlet="validationMessages; context: { $implicit: 'locationId' }"></ng-container>
      </div>

      <div class="form-field"
           *ngIf="villageRegLandConsDropDownData?.villageRegLandCons && villageRegLandConsDropDownData?.villageRegLandCons.length > 0 && fundingProjectForm.get('fundingMeasureId')?.valid && (locations && locations.length > 0)  && selectedMesureIsVillageRegOrLandCons">
        <div class="label required">
          <label for="villageRegLandCon">
            {{ villageRegLandConsDropDownData?.type }}
          </label>
        </div>
        <input type="text"
               id="villageRegLandCon"
               formControlName="villageRegLandConId"
               placeholder="{{ 'fundingProject.fields.pleaseSelect' | translate }}"
               [matAutocomplete]="lAuto" required>
        <mat-autocomplete #lAuto="matAutocomplete" [displayWith]="getVillageRegLandConTitle.bind(this)">
          <mat-option *ngFor="let villageRegLandCon of (filteredVillageRegLandCons$ | async)" [value]="villageRegLandCon.id">
            {{ villageRegLandCon.title }}
          </mat-option>
        </mat-autocomplete>
        <ng-container *ngTemplateOutlet="validationMessages; context: { $implicit: 'villageRegLandConId' }"></ng-container>
      </div>

      <div class="form-field">
        <div class="label required">
          <label for="description">
            {{ 'fundingProject.fields.description' | translate }}
          </label>
        </div>
        <textarea [style.resize]="'none'" id="description" formControlName="description" rows="10"
                  required></textarea>
        <ng-container *ngTemplateOutlet="validationMessages; context: { $implicit: 'description' }"></ng-container>
      </div>
    </form>

    <div class="create-project-actions">
      <button
        mat-stroked-button
        type="submit"
        [disabled]="!fundingProjectForm.valid"
        (click)="createNewFundingProject()">
        {{ 'fundingProject.create.createProject' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #validationMessages let-name>
  <div
    class="validation-msg"
    *ngIf="
      fundingProjectForm.controls[name].invalid &&
      (fundingProjectForm.get(name)?.dirty ||
        fundingProjectForm.get(name)?.touched)
    "
  >
    <div *ngIf="fundingProjectForm.get(name)?.errors?.required">
      {{ 'validationErrorMessages.required' | translate }}
    </div>
    <div *ngIf="fundingProjectForm.get(name)?.errors?.minlength">
      {{ 'validationErrorMessages.minLength' | translate }}
    </div>
    <div *ngIf="fundingProjectForm.get(name)?.errors?.maxlength">
      {{ 'validationErrorMessages.maxLength' | translate: {lengthVar: maxLengthFundingProjectTitle} }}
    </div>
    <div *ngIf="fundingProjectForm.get(name)?.errors?.max">
      {{
        'fundingProject.create.validationErrorMessages.maxFundingValue'
          | translate: { maxValue: maxFundingValueLimit }
      }}
    </div>
    <div *ngIf="fundingProjectForm.get(name)?.errors?.min">
      {{
        'fundingProject.create.validationErrorMessages.minFundingValue'
          | translate
      }}
    </div>
    <div *ngIf="fundingProjectForm.get(name)?.errors?.forbiddenString">
      {{ 'validationErrorMessages.autocompleteError' | translate }}
    </div>
    <div *ngIf="fundingProjectForm.get(name)?.errors?.pattern">
      {{ 'validationErrorMessages.forbiddenCharacters' | translate: { forbiddenChars: forbiddenSpecialChars } }}
    </div>
  </div>
</ng-template>
