import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { NgxCurrencyInputMode } from 'ngx-currency';
import { FormlyFieldConfig } from '@ngx-formly/core/lib/models';
import { TotalCostFieldObserverService } from '../../services/total-cost-field-observer.service';
import { ActivatedRoute } from '@angular/router';
import {
  FORM_TEMPLATE_LEADER,
  FORM_TEMPLATE_ZILE,
  INPUT,
  REQUEST_STATE_CREATED,
  STYLE_WITH_RED_BORDERS,
  STYLE_WITHOUT_RED_BORDERS,
  TOTAL_COSTS_REQUESTED,
  TOTAL_COSTS_REVENUE_TFE
} from '../../utils/forms-constants';
import { FormTemplate } from '../../interfaces/form-template';

@Component({
  selector: 'oaman-form-cost-input-financial-plan-type',
  template: `
    <!-- Use for input field with decimal value presentation (e.g. 1.458,95) -->
    <input
      *ngIf="type === 'cost'"
      [type]="'tel'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      currencyMask
      [options]="{
        prefix: '',
        thousands: '.',
        decimal: ',',
        nullable: 'false',
        allowNegative: 'false',
        inputMode: inputMode
      }"
      [value]="formattedValue"
    />
    <!-- Use for input field with decimal value presentation with currency sign (e.g. 1.458,95 €) -->
    <input
      *ngIf="type === 'cost-cur'"
      [type]="'tel'"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
      currencyMask
      [options]="{
        prefix: '',
        suffix: ' €',
        thousands: '.',
        decimal: ',',
        nullable: 'false',
        allowNegative: 'false',
        inputMode: inputMode
      }"
      [value]="formattedValue"
    />
    <input
      *ngIf="type !== 'cost' && type !== 'cost-cur'"
      [type]="type"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (focus)="props['focused'] = true"
      (blur)="props['focused'] = false"
    />
  `
})
export class FormCostInputFinancialPlanComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  // Some Formly-Getters-part
  get type() {
    return this.props.type || 'number';
  }
  get inputMode() {
    return NgxCurrencyInputMode.Natural;
  }
  //These getters are based on a fixed structure of the forms. If the structure changes, the indexes must also be adapted here.
  //The path to totalCostsRevenueInput is the same for LEADER and ZILE. That is why a common getter is used.
  get totalCostsRevenueInput(): FormlyFieldConfig {
    return this.field.parent?.parent?.parent?.parent?.fieldGroup![1]
      .fieldGroup![1].fieldGroup![1].fieldGroup![0] as FormlyFieldConfig;
  }
  get zileTotalCostsReqInput(): FormlyFieldConfig {
    return this.field.parent?.parent?.parent?.parent?.fieldGroup![0]
      .fieldGroup![6].fieldGroup![1].fieldGroup![0] as FormlyFieldConfig;
  }
  get leaderTotalCostsReqInput(): FormlyFieldConfig {
    return this.field.parent?.parent?.parent?.parent?.fieldGroup![0]
      .fieldGroup![4].fieldGroup![1].fieldGroup![0] as FormlyFieldConfig;
  }
  //End of Formly-Getters-part

  private formTitle!: string;
  formattedValue: string | undefined;
  private currentRequestState!: string | undefined;

  constructor(
    private totalCostFieldObserverService: TotalCostFieldObserverService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.formTitle = data['resolvedData']['title'];
      let allForms: FormTemplate[] = data['resolvedForms'];
      let currentForm = allForms?.find((form) => form.title === this.formTitle);
      this.currentRequestState = currentForm?.requestAndType.stateResourceKey;
    });

    // Ensure the value is a number, defaulting to 0 if it's not set or not a number
    const initialValue = this.toNumber(this.formControl.value, 0);
    this.formControl.setValue(initialValue);
    this.formattedValue = this.formatValue(initialValue);

    if (REQUEST_STATE_CREATED === this.currentRequestState) {
      if (
        FORM_TEMPLATE_ZILE === this.formTitle ||
        FORM_TEMPLATE_LEADER == this.formTitle
      ) {
        if (
          this.field.key === TOTAL_COSTS_REQUESTED + INPUT ||
          this.field.key === TOTAL_COSTS_REVENUE_TFE + INPUT
        ) {
          //Subscribing for BorderColor changes.
          this.totalCostFieldObserverService.data$.subscribe((sameValue) => {
            const newBorderColor = !sameValue
              ? STYLE_WITH_RED_BORDERS
              : STYLE_WITHOUT_RED_BORDERS;
            this.changeBordersColorToRed(newBorderColor);
          });
          //Compare the Init-Sum-Value coming from the Database.
          this.totalCostFieldObserverService.updateData(
            this.compareSumFields()
          );
          //Subscribing for formControl valueChanges.
          this.formControl.valueChanges.subscribe(() => {
            this.totalCostFieldObserverService.updateData(
              this.compareSumFields()
            );
          });
        }
      }
    }
  }

  private changeBordersColorToRed(newBorderColor: any) {
    this.totalCostsRevenueInput.props!.attributes = {
      style: newBorderColor
    };
    if (FORM_TEMPLATE_ZILE === this.formTitle) {
      this.zileTotalCostsReqInput.props!.attributes = {
        style: newBorderColor
      };
    }
    if (FORM_TEMPLATE_LEADER === this.formTitle) {
      this.leaderTotalCostsReqInput.props!.attributes = {
        style: newBorderColor
      };
    }
  }

  private compareSumFields(): boolean {
    return FORM_TEMPLATE_ZILE === this.formTitle
      ? this.isSumEqual(this.zileTotalCostsReqInput.formControl?.value)
      : this.isSumEqual(this.leaderTotalCostsReqInput.formControl?.value);
  }

  private isSumEqual(sumToCompare: any): boolean {
    return (
      this.toNumber(sumToCompare, 0) ===
      this.toNumber(this.totalCostsRevenueInput.formControl?.value, 0)
    );
  }

  private toNumber(value: any, defaultValue: number): number {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }

  private formatValue(value: number): string {
    // Format the value as needed, here for example as '0 €'
    return `${value.toFixed(2).replace('.', ',')} €`;
  }
}
