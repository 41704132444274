import { DatePipe } from '@angular/common';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake, { TCreatedPdf } from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import {
  findIndex as _findIndex,
  find as _find,
  countBy as _countBy,
  isArray
} from 'lodash';
import {
  Content,
  Style,
  Node,
  TDocumentDefinitions,
  TDocumentInformation
} from 'pdfmake/interfaces';
import { User } from '../../../core/interfaces/user';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldFormatter } from './field-formatter/field-formatter';
import { FieldGroupFormatter } from './field-group-formatter/field-group-formatter';

/**
 * Class for creating a filled PDF representation of a formly form.
 */
export class FormToPdfGenerator {
  private fieldGroup: FormlyFieldConfig[];
  private formModel: any;
  private html: string;
  private author: string;
  private creator: string;
  private datePipe: DatePipe;
  private decimalFormatter;
  private fieldFormatters: FieldFormatter[];
  private fieldGroupFormatters: FieldGroupFormatter[];
  private currentUser?: User;
  private formTitle?: string;
  private compressPdf: boolean;

  // Images
  private checkboxRef =
    'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABISURBVDhPY/hPAQBrfv78+f9p06b9LysrI4hB6kDq4ZpBAseOHQMLEAIgdSD1IADWDDKRFABTP6qZSDAcNFOUwihK2+SB//8BqSs5OrEDBqUAAAAASUVORK5CYII=';
  private checkboxCheckedRef =
    'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACySURBVDhPpZIxCsQgEEX31LbByjalra2l19ArpIxH0HKWP+AwboSEzYcPw/DfZDR+6IUYzjmTtZaMMbdGDnmBn4LDyAu8Cmhv20bHcVAIQXqP4VIKh3vv0oNu4ZQSB6F936UPXWCsOGrvPYegGOOUgyYYgdYaf8E5xzWEtTUIQxNca+UmoPM8uUZPQ8PQBGPlMQDCBeljaEPLM48B+oJ+DV1gGAP0P11Z4Fcv7NXb/k9EX0BEnlQTBf7kAAAAAElFTkSuQmCC';
  private radioButtonRef =
    'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAEMSURBVChTnZExi4NAEEbvv+fQSgQjKGqqSAqxMtrlFKKmTBEJpBFDCgslaCFYfseOGw8viUVeNfPtvl1m9wsf8CRdr1eEYQjf9xHHMaqq4it/jFLf91iv19B1Hfv9HsfjEbvdDyRJwna75bsGRknTNCRJwrspnufBdV3ecSmKoqfT/mNZFi6XC9UkybKM+/1OwTuyLIPjOFSTtFgsqJmjbVssl0uqufRNzRzsFdncDJJM00RRFBS843A4IAgCqkk6nU5YrVYUvKLrOgiCMP4ZSQz2erZto2kangzcbjeahd30YJQYaZpCFEUYhoHNZgNVVaEoCs7nM98xMJEelGWJPM9R1zVPpryU5gF+AQx1qIjmJ5DMAAAAAElFTkSuQmCC';
  private radioButtonActiveRef =
    'data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOCAYAAAD0f5bSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFGSURBVChTnZFNq4JQEIbvr27Zpku5CaHCpI+V0SIiMHXXVSiNCHRRBBFEHwsXRSQiuOuNmcyQK3dxHzg4o/Mcz5n5wj/4JR2PR5imCV3XMZvNcLlcki8fUimOYyiKAlmWMZ1O4XkeJpMfVKtVaJqWVL1IpWazCdu2Od7tdlgulzidTpyPx2MMh0OOCZYsy+LdHo8Hut0uCoVCukajERd2Oh1st1uOWRJFEbfbDYZhZIT3oqOu12sMBoOPVCqVOCE5T6LiIAhQr9e5LpG+OZEkKVdSVZW7SPcmWGq32zgcDlgsFrkSjWE+n/PxU2m1WnEDCJpRsVjkYkEQ4LouoihCuVxOZ8YSQd3r9Xq43++ch2HIz/P5zHehP71JJcJxHFQqFbRaLfT7fTQaDdRqNWw2m6TiRUZ64/s+9vs9rtdr8iZLrvQ3wBPrE3MtzOwJzQAAAABJRU5ErkJggg==';

  /**
   * Constructor
   *
   * @param fieldGroup the form template
   * @param formModel the form model
   * @param fieldFormatters the defined field formattes
   * @param fieldGroupFormatters the defined field group formatters
   * @param currentUser the current User object
   * @param formTitle the title of the form
   * @param compressPdf the compression of the generated pdf (default: true)
   */
  constructor(
    fieldGroup: FormlyFieldConfig[],
    formModel: any,
    fieldFormatters: FieldFormatter[] | undefined,
    fieldGroupFormatters: FieldGroupFormatter[] | undefined,
    currentUser: User | undefined,
    formTitle: string | undefined,
    compressPdf: boolean = true
  ) {
    this.fieldGroup = fieldGroup;
    this.formModel = formModel;
    this.html = '';
    this.currentUser = currentUser;
    this.author =
      this.currentUser && this.currentUser.fullName
        ? this.currentUser.fullName
        : 'OAMan ÄrL';
    this.creator = 'OAMan ÄrL';
    this.formTitle = formTitle;
    this.datePipe = new DatePipe('de-DE');
    this.decimalFormatter = new Intl.NumberFormat('de-DE', {
      /* style: 'currency',
      currency: 'EUR',     (this will print a number like 2,50 €)*/
      minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2 // (causes 2500.99 to be printed as $2,501)
    });
    this.fieldFormatters = fieldFormatters ? fieldFormatters : [];
    this.fieldGroupFormatters = fieldGroupFormatters
      ? fieldGroupFormatters
      : [];
    this.compressPdf = compressPdf;
  }

  /**
   * Execution of the generation of the pdf object.
   *
   * @returns the PDFMake TCreatedPdf object
   * (Methods see https://pdfmake.github.io/docs/0.1/getting-started/client-side/methods/)
   */
  public generatePdf(): TCreatedPdf {
    // console.log('fieldGroup: ', this.fieldGroup);
    // console.log('formModel: ', this.formModel);
    this.transformFormToHtml();
    // console.log('generated html: ', this.html);
    const content = htmlToPdfmake(
      this.html,
      this.generateHtmlToPdfmakeOptions()
    );
    // console.log('generated content: ', content);
    const documentDefinition: TDocumentDefinitions = {
      info: this.generateDocInfo(),
      compress: this.compressPdf,
      content: content,
      defaultStyle: this.generateDefaultStyle(),
      header: (currentPage: any, pageCount: any, pageSize: any) =>
        this.generateHeaderFunction(currentPage, pageCount, pageSize),
      footer: (currentPage: any, pageCount: any, pageSize: any) =>
        this.generateFooterFunction(currentPage, pageCount, pageSize),
      pageBreakBefore: (
        currentNode: Node,
        followingNodesOnPage: Node[],
        nodesOnNextPage: Node[],
        previousNodesOnPage: Node[]
      ) =>
        this.generatePageBreakBefore(
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
          previousNodesOnPage
        ),
      pageMargins: [60, 70, 40, 70]
    };
    // console.log('generated documentDefinition: ', documentDefinition);
    return pdfMake.createPdf(documentDefinition);
  }

  /**
   * Definition of the html-to-pdfmake options
   *
   * @returns the options
   */
  generateHtmlToPdfmakeOptions() {
    return {
      tableAutoSize: true
    };
  }

  /**
   * Creation of the document information.
   *
   * @returns the TDocumentInformation
   */
  generateDocInfo(): TDocumentInformation {
    const titel = this.formTitle
      ? this.formTitle
      : this.getFormTitle(this.fieldGroup);
    const subject = this.formTitle ? this.getFormTitle(this.fieldGroup) : '';
    return {
      title: titel,
      author: this.author,
      subject: subject,
      keywords:
        'OAMan ÄrL;Online Antragsmanagement;Ämter für regionale Landesentwicklung' +
        (titel ? ';' + titel : '') +
        (subject ? ';' + subject : ''),
      creator: this.creator,
      producer: this.creator + ' (PDF Creator)'
    };
  }

  /**
   * Creation of the page header.
   *
   * @param currentPage the number of the current page
   * @param pageCount the number of all pages
   * @param pageSize the size of the page
   *
   * @returns the header (Content)
   */
  generateHeaderFunction(
    currentPage: any,
    pageCount: any,
    pageSize: any
  ): Content {
    return {
      columns: [
        { text: '', alignment: 'left', fontSize: 10 },
        { text: '', alignment: 'center', fontSize: 10 },
        {
          text: '',
          alignment: 'right',
          fontSize: 10,
          bold: true
        }
      ],
      margin: [60, 35, 40, 40]
    };
  }

  /**
   * Creation of the page footer.
   *
   * @param currentPage the number of the current page
   * @param pageCount the number of all pages
   * @param pageSize the size of the page
   *
   * @returns the footer (Content)
   */
  generateFooterFunction(
    currentPage: any,
    pageCount: any,
    pageSize: any
  ): Content {
    return {
      columns: [
        { text: '', alignment: 'left', fontSize: 10 },
        { text: '', alignment: 'center', fontSize: 10 },
        {
          text: currentPage.toString(),
          alignment: 'right',
          fontSize: 10
        }
      ],
      margin: [60, 35, 40, 40]
    };
  }

  /**
   * Treatment of page breakes.
   *
   * @param currentNode the current page node
   * @param followingNodesOnPage the following nodes on the page
   * @param nodesOnNextPage the following nodes on the next page
   * @param previousNodesOnPage the nodes on the previous page
   *
   * @returns true for adding a page break else false
   */
  generatePageBreakBefore(
    currentNode: Node,
    followingNodesOnPage: Node[],
    nodesOnNextPage: Node[],
    previousNodesOnPage: Node[]
  ): boolean {
    // Break before a <h2>
    /* console.log(
      'PB style: ',
      currentNode.style?.toString(),
      ' | currentNode: ',
      currentNode
    ); */

    // Break <h2> if here is no <h1> on the same page
    if (currentNode.style?.toString().includes('html-h2')) {
      const previuousH1Node = _find(
        previousNodesOnPage,
        (node) => node.style?.toString().includes('html-h1')
      );
      return !previuousH1Node ? true : false;
    }

    // Breake <h3>
    if (currentNode.style?.toString().startsWith('html-h3')) {
      /* console.log(
        'Breake <h3> - followingNodesOnPage.length',
        followingNodesOnPage.length,
        ' | previousNodesOnPage.length',
        previousNodesOnPage.length
      ); */
      return (
        previousNodesOnPage.length > 50 && followingNodesOnPage.length < 25
      );
    }

    // Breake tables if the following tr or td < x
    if (currentNode.style?.toString().startsWith('html-table')) {
      const countDict = _countBy(followingNodesOnPage, (node) =>
        node.style?.toString().startsWith('html-td')
          ? 'html-td'
          : node.style?.toString()
      );
      /* console.log(
        'Breake tables - countDict["html-td"]: ',
        countDict['html-td'],
        ' | followingNodesOnPage.length',
        followingNodesOnPage.length,
        ' | previousNodesOnPage.length',
        previousNodesOnPage.length
      ); */
      return (
        countDict['html-td'] < 6 &&
        followingNodesOnPage.length <= countDict['html-td'] &&
        previousNodesOnPage.length > 30
      );
    }

    return false;
  }

  /**
   * Definition of default styles
   *
   * @returns the default syles (Style)
   */
  generateDefaultStyle(): Style {
    return {
      table: { marginBottom: 0 }
    } as Style;
  }

  /**
   * Transformation of the formly form to a html string (this.html).
   */
  transformFormToHtml() {
    if (this.fieldGroup && this.formModel) {
      this.html = '';
      this.writeHtml('<div class="print-content">');
      this.processTitle(this.fieldGroup);
      this.processFieldGroup(this.fieldGroup, undefined);
      this.writeHtml('</div>');
    } else {
      console.error('Parameters not initialized!');
    }
  }

  /**
   * Adding titel to html from form template.
   *
   * @param fieldGroup the form template
   */
  processTitle(fieldGroup: FormlyFieldConfig[] | undefined) {
    let title: string = this.getFormTitle(fieldGroup);
    if (title) {
      this.writeHtml('<div class="title"><h1>' + title + '</h1></div>');
    }
  }

  /**
   * Extract titel from form template.
   *
   * @param fieldGroup the form template
   *
   * @returns the title
   */
  getFormTitle(fieldGroup: FormlyFieldConfig[] | undefined): string {
    let currentFieldGroup = fieldGroup;
    let titleFound: boolean = false;
    let title = '';
    do {
      if (currentFieldGroup && currentFieldGroup.length > 0) {
        let field = currentFieldGroup[0];
        if (field && field.type === 'page' && field?.templateOptions?.title) {
          title = field?.templateOptions?.title.replace(' - ', '');
          titleFound = true;
        } else {
          currentFieldGroup = field.fieldGroup;
        }
      } else {
        console.warn('Formtitle not found!');
        break;
      }
    } while (!titleFound);
    return title;
  }

  /**
   * Process given/current field or group of fields from form template.
   *
   * @param field the given/current field or group of fields
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processFieldGroup(
    field: FormlyFieldConfig | FormlyFieldConfig[] | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field) {
      let parentField = undefined;
      let parentFieldType: any;
      let fieldGroup;
      if (!Array.isArray(field)) {
        parentField = field;
        parentFieldType = field?.type;
        fieldGroup = field.fieldGroup;
        breadcrumbKey = this.generateModelKey(field, breadcrumbKey);
      } else {
        fieldGroup = field;
      }

      if (
        breadcrumbKey &&
        _findIndex(this.fieldGroupFormatters, (formatter) =>
          formatter.breadcrumpKeys.includes(breadcrumbKey as string)
        ) !== -1
      ) {
        this.processByFieldGroupFormatter(
          parentField,
          fieldGroup,
          breadcrumbKey
        );
      } else if (fieldGroup) {
        fieldGroup.forEach((field) => {
          this.processField(field, breadcrumbKey);
          if (parentFieldType === 'repeat') {
            // add an empty line between repeated group elements
            this.writeHtml('<br>');
          }
        });
      }
    }
  }

  /**
   * Process field group formatters on current field group of form template.
   * If a custom formatter is defined for the current field group it is used for
   * generating the following html code instead of using the default generation
   * process.
   *
   * @param parentField the parent field
   * @param fieldGroup the current field group
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processByFieldGroupFormatter(
    parentField: FormlyFieldConfig | undefined,
    fieldGroup: FormlyFieldConfig[] | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (breadcrumbKey) {
      const fieldGroupFormatter = _find(
        this.fieldGroupFormatters,
        (formatter) =>
          formatter.breadcrumpKeys.includes(breadcrumbKey?.toString())
      );
      if (fieldGroupFormatter) {
        this.writeHtml(
          fieldGroupFormatter.generateFormatedFieldGroupTag(
            this,
            parentField,
            fieldGroup,
            breadcrumbKey
          )
        );
      }
    }
  }

  /**
   * Process given/current field from form template.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processField(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    /*
      FIXME RHI: If the pdf transformation is executed outside the FormlyForm,
      the field hide is nocht set. In that case we need to pasrs the field.hideExpression.
      That is not implemented jet. We only need this, if we want to call the pdf generation outside the Form View.
    */
    if (field && !field.hide) {
      switch (field.type) {
        case 'navigation':
          this.processNavigation(field);
          break;
        case 'page-group':
          this.processPageGroup(field, breadcrumbKey);
          break;
        case 'page':
          this.processPage(field, breadcrumbKey);
          break;
        case 'sub-group':
        case 'formly-group':
        case 'repeat':
          this.processSubGroup(field, breadcrumbKey);
          break;
        case 'form-input':
        case 'form-cost-input':
        case 'form-cost-input-financial-plan':
        case 'form-select':
        case 'form-ng-multi-select-dropdown':
        case 'year':
        case 'input':
        case 'date-picker':
          this.processFormInput(field, breadcrumbKey);
          break;
        case 'form-checkbox':
          this.processFormCheckbox(field, breadcrumbKey);
          break;
        case 'checkbox':
          this.processCheckbox(field, breadcrumbKey);
          break;
        case 'radio':
          this.processRadioButton(field, breadcrumbKey);
          break;
        case 'textarea':
          this.processTextArea(field, breadcrumbKey);
          break;
        case 'explanation-attachment-group':
          this.processContentGroupL4(field, breadcrumbKey);
          break;
        case 'formly-template':
          this.processFormlyTemplate(field);
          break;
        case 'cost-calculation-table':
          this.processCostCalculationTable(field, breadcrumbKey);
          break;
        case 'cost-calculation':
        case 'cost-calculation-financial-plan':
          this.writeHtml('<table style="width: 100%;">');
          this.writeHtml('<tbody>');
          this.processCostCalculation(
            field,
            breadcrumbKey,
            field?.templateOptions?.hasSumColumn,
            false,
            this.getMaxColumnCount(field)
          );
          this.writeHtml('</tbody>');
          this.writeHtml('</table>');
          break;
        default:
          // check if field is template without type
          if (field.template) {
            this.processFormlyTemplate(field);
          } else {
            const label = this.getFieldLabel(field);
            console.warn(
              'Unknown fieldtype: ',
              field.type + (label ? ' | label: ' + label : '')
            );
          }
          break;
      }
    }
  }

  /**
   * Process field type 'navigation'.
   *
   * @param field the given/current field
   */
  processNavigation(field: FormlyFieldConfig | undefined) {
    if (field && field?.type === 'navigation') {
      this.processFieldGroup(field, undefined);
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'page-group'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processPageGroup(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'page-group') {
      this.writeHtml('<div class="page-group">');
      const fieldLabel: string = this.getFieldLabel(field);
      if (fieldLabel) {
        this.writeHtml('<h2>' + fieldLabel + '</h2>');
      }
      this.writeDescriptionTag(field);
      this.processFieldGroup(field, breadcrumbKey);
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'page'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processPage(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'page') {
      this.writeHtml('<div class="page">');
      const fieldLabel: string = this.getFieldLabel(field);
      if (fieldLabel) {
        this.writeHtml('<h3>' + fieldLabel + '</h3>');
      }
      this.writeDescriptionTag(field);
      this.processFieldGroup(field, breadcrumbKey);
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'sub-group' / 'formly-group' / 'repeat'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processSubGroup(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (
      field &&
      (field?.type === 'sub-group' ||
        field?.type === 'formly-group' ||
        field?.type === 'repeat')
    ) {
      this.writeHtml('<div class="sub-group">');

      // Write the tamplete value tages only if the following
      // fieldgroup is not procced by a fieldGroupFormatter
      if (
        !(
          field?.type === 'formly-group' &&
          breadcrumbKey &&
          _findIndex(this.fieldGroupFormatters, (formatter) =>
            formatter.breadcrumpKeys.includes(
              this.generateModelKey(field, breadcrumbKey)
            )
          ) !== -1
        )
      ) {
        this.writeSubGroupTemplateValueTags(field);
      }
      this.processFieldGroup(field, breadcrumbKey);
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'explanation-attachment-group'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processContentGroupL4(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'explanation-attachment-group') {
      this.writeHtml('<div class="content-group-l3">');
      const fieldLabel: string = this.getFieldLabel(field);
      if (fieldLabel) {
        this.writeHtml('<h4>' + fieldLabel + '</h4>');
      }
      this.processFieldGroup(field, breadcrumbKey);
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'formly-template'.
   *
   * @param field the given/current field
   */
  processFormlyTemplate(field: FormlyFieldConfig | undefined) {
    if (field && (field?.type === 'formly-template' || field.template)) {
      this.writeHtml('<div class="template">');
      const template = this.replaceSpaces(field.template);
      this.writeHtml(template ? template : '');
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field of different input types.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processFormInput(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field) {
      switch (field?.type) {
        case 'form-input':
        case 'form-cost-input':
        case 'form-cost-input-financial-plan':
        case 'form-select':
        case 'form-ng-multi-select-dropdown':
        case 'year':
        case 'input':
        case 'date-picker':
        case 'cost':
        case 'cost-cur':
          this.writeInputField(field, undefined, undefined, breadcrumbKey);
          break;
        default:
          console.warn(
            'Parameter not initialized or unknown fieldtype: ',
            field?.type
          );
          break;
      }
    }
  }

  /**
   * Adding input field to html from form template.
   *
   * @param field the given/current field
   * @param headline the headline of the input field
   * @param content the content string of the input field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  writeInputField(
    field: FormlyFieldConfig | undefined,
    headline: string | undefined,
    content: string | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    this.writeHtml('<div class="input">');
    if (
      (field && this.containsTemplateValueTags(field)) ||
      headline ||
      field?.wrappers?.includes('field-wrapper')
    ) {
      if (field) {
        this.writePointTag(field);
        this.writeDescriptionTag(field);
      }

      this.writeHtml(
        '<table style="width: 100%;"><tr><td style="width: 100%;">'
      );
      this.writeHtml(
        '<table style="width: 100%; margin-bottom: 0px;"><tr><td style="border: none; font-size: 10px;">'
      );
      // If headline is defined it overrides the maybe existing template value of the field! headline = first!
      if (headline) {
        this.writeHtml('<div class="label">' + headline + '</div>');
      } else if (field) {
        this.writeLabelTag(field);
      }
      this.writeHtml(
        '</td></tr><tr><td style="width: 100%; border: none; margin-left: 15px; font-weight: bold;">'
      );
      // If content is defined it overrides the maybe existing value of the field! content = first!
      let value = content;
      if (!value && field) {
        value = this.getFieldValue(field, breadcrumbKey, '-');
      }
      this.writeHtml('<div class="value">' + value + '</div>');
      this.writeHtml('</td></tr></table>');
      this.writeHtml('</td></tr></table>');
    } else if (field?.wrappers?.includes('intext-field-wrapper')) {
      this.processIntextFieldWrapper(field, breadcrumbKey);
    } else if (field) {
      this.writeHtml(
        '<div class="value">' +
          this.getFieldValue(field, breadcrumbKey, '-') +
          '</div>'
      );
    }
    this.writeHtml('</div>');
  }

  /**
   * Adding intext field to html from form template.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processIntextFieldWrapper(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field?.wrappers?.includes('intext-field-wrapper')) {
      const placehoder = field.templateOptions?.placeholder
        ? field.templateOptions?.placeholder
        : '-';
      const text1 = field.templateOptions?.text1
        ? field.templateOptions?.text1
        : '';
      const text2 = field.templateOptions?.text1
        ? field.templateOptions?.text2
        : '';
      const value = this.getFieldValue(field, breadcrumbKey, placehoder);
      this.writeHtml(
        '<div class="value"><p>' +
          text1 +
          ' <b>' +
          value +
          '</b> ' +
          text2 +
          '</p></div>'
      );
    }
  }

  /**
   * Process field type 'textarea'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processTextArea(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'textarea') {
      let marginTop = this.containsTemplateValueTags(field)
        ? 'margin-top: 2px;'
        : '';

      this.writeHtml('<div class="textarea">');
      this.writeTemplateValueTags(field);
      this.writeHtml('<table><tr><td style="width: 100%"; ' + marginTop + '>');
      this.writeHtml(
        '<table style="width: 100%; margin-bottom: 0px;"><tr><td style="border: none;">'
      );
      this.writeHtml(this.getFieldValue(field, breadcrumbKey, '-'));
      this.writeHtml('</td></tr></table>');
      this.writeHtml('</td></tr></table>');
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'form-checkbox'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processFormCheckbox(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'form-checkbox') {
      this.writeHtml('<div class="input">');
      this.writeTemplateValueTags(field);
      this.writeHtml(
        '<div class="checkbox">' +
          this.getCheckboxTag(
            this.getFieldValue(field, breadcrumbKey, undefined)
          ) +
          '</div>'
      );
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'checkbox'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processCheckbox(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'checkbox') {
      this.writeHtml('<div class="checkbox">');
      this.writePointTag(field);
      this.writeDescriptionTag(field);
      this.writeHtml(this.getCheckboxTableTag(field, breadcrumbKey));
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'radio'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processRadioButton(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'radio') {
      this.writeHtml('<div class="input">');
      this.writeTemplateValueTags(field);
      this.writeHtml(
        '<div class="radio">' +
          this.getRadioButtonValue(field, breadcrumbKey) +
          '</div>'
      );
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'cost-calculation-table'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processCostCalculationTable(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    if (field && field?.type === 'cost-calculation-table') {
      const hasSumColumn: boolean = field?.templateOptions?.hasSumColumn;
      const hasTotalSumRow: boolean = field?.templateOptions?.hasTotalSumRow;
      const fieldGroup = field?.fieldGroup;
      const maxColumnCount: number = this.getMaxColumnCount(field);

      if (maxColumnCount === -1) {
        console.warn(
          'Cost calculation - Max column count could not be determined: ',
          maxColumnCount
        );
        return;
      }

      this.writeHtml('<div class="cost-calculation-table">');
      this.writeLabelTag(field);
      this.writeHtml('<table style="width: 100%;">');
      this.writeHtml('<tbody>');

      if (fieldGroup) {
        fieldGroup.forEach((innerField, idx, fieldGroup) => {
          const isTotalSumRow = hasTotalSumRow && idx === fieldGroup.length - 1;
          switch (innerField.type) {
            case 'cost-calculation':
            case 'cost-calculation-financial-plan':
              this.processCostCalculation(
                innerField,
                this.generateModelKey(field, breadcrumbKey),
                hasSumColumn,
                isTotalSumRow,
                maxColumnCount
              );
              break;
            case 'formly-group':
              this.processFormlyGroup(
                innerField,
                this.generateModelKey(field, breadcrumbKey),
                hasSumColumn,
                isTotalSumRow,
                maxColumnCount
              );
              break;
            default:
              console.warn('Unknown fieldtype: ', innerField.type);
              break;
          }
        });
      }

      this.writeHtml('</tbody>');
      this.writeHtml('</table>');
      this.writeHtml('</div>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Calculate the width of the first column of a 'cost-calculation-table'.
   *
   * @param maxColumnCount the count of all columns
   *
   * @returns the width of the first column of a 'cost-calculation-table'
   */
  getFirstColumnWidth(maxColumnCount: number): number {
    switch (maxColumnCount) {
      case 1:
        return 100;
      case 2:
        return 45;
      case 3:
        return 40;
      case 4:
        return 35;
      case 5:
        return 30;
      case 6:
        return 25;
      default:
        return 16;
    }
  }

  /**
   * Calculate the font size of a 'cost-calculation-table'.
   *
   * @param maxColumnCount the count of all columns
   *
   * @returns the font size of a 'cost-calculation-table'
   */
  getCostCalculationFontSize(maxColumnCount: number): number {
    switch (maxColumnCount) {
      case 6:
        return 14;
      case 7:
        return 11;
      default:
        return 16;
    }
  }

  /**
   * Process field type 'cost-calculation', 'cost-calculation-financial-plan'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  processCostCalculation(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined,
    hasSumColumn: boolean,
    isTotalSumRow: boolean,
    maxColumnCount: number
  ) {
    if (
      field &&
      (field?.type === 'cost-calculation' ||
        field?.type === 'cost-calculation-financial-plan')
    ) {
      this.writeHtml('<tr>');

      const fieldLabel: string = this.getFieldLabel(field);
      const fieldGroup = field.fieldGroup;
      let maxColumnCountWork: number = maxColumnCount;
      let fontSize: number = this.getCostCalculationFontSize(maxColumnCount);
      let firstColWidth = this.getFirstColumnWidth(maxColumnCount);

      // First column is text?
      if (fieldLabel) {
        let colSpan = '';

        // There are no other column in this row?
        if (!fieldGroup || fieldGroup.length == 0) {
          colSpan = 'colspan="' + maxColumnCount + '"';
          firstColWidth = 100;
        }

        this.writeHtml(
          '<td ' +
            colSpan +
            ' style="width: ' +
            firstColWidth +
            '%; font-size: ' +
            fontSize +
            'px;">' +
            fieldLabel +
            '</td>'
        );

        // We can finish this row, cause there are
        // no other columns in this table row
        if (colSpan) {
          this.writeHtml('</tr>');
          return;
        }
        maxColumnCountWork--;
      }

      // Write the other columns in this row
      if (fieldGroup && fieldGroup.length > 0) {
        let colWidth = Math.round((100 - firstColWidth) / maxColumnCountWork);
        fieldGroup.forEach((innerField, idx, fieldGroup) => {
          // Correct for last column with
          if (
            idx == fieldGroup.length - 1 &&
            maxColumnCountWork == fieldGroup.length
          ) {
            colWidth =
              colWidth -
              (maxColumnCountWork * colWidth - (100 - firstColWidth));
          }

          // If is sum row, do some styling!
          if (
            isTotalSumRow ||
            (hasSumColumn &&
              idx == fieldGroup.length - 1 &&
              maxColumnCountWork == fieldGroup.length)
          ) {
            this.writeHtml(
              '<td style="background-color:#DCDCDC; width: ' +
                colWidth +
                '%; text-align: right; font-size: ' +
                fontSize +
                'px;">'
            );
          } else {
            this.writeHtml(
              '<td style="width: ' +
                colWidth +
                '%; text-align: right; font-size: ' +
                fontSize +
                'px;">'
            );
          }

          this.processField(
            innerField,
            this.generateModelKey(field, breadcrumbKey)
          );

          this.writeHtml('</td>');
        });

        // Add missing columns
        if (maxColumnCountWork > fieldGroup.length) {
          for (
            let index = fieldGroup.length;
            index < maxColumnCountWork;
            index++
          ) {
            if (index == maxColumnCountWork - 1) {
              colWidth =
                colWidth -
                (maxColumnCountWork * colWidth - (100 - firstColWidth));
            }

            this.writeHtml('<td style="width: ' + colWidth + '%;"></td>');
          }
        }
      }

      this.writeHtml('</tr>');
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Process field type 'formly-group'.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   * @param hasSumColumn true if there is a sum column else false
   * @param isTotalSumRow true if it is a totel sum row eles false
   * @param maxColumnCount the count of all columns
   */
  processFormlyGroup(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined,
    hasSumColumn: boolean,
    isTotalSumRow: boolean,
    maxColumnCount: number
  ) {
    if (field && field?.type === 'formly-group') {
      const fieldLabel: string = this.getFieldLabel(field);
      if (fieldLabel) {
        this.writeHtml(
          '<tr><td colspan="' +
            maxColumnCount +
            '" style="width: 100%; font-size: ' +
            this.getCostCalculationFontSize(maxColumnCount) +
            'px; font-weight: bold;">' +
            fieldLabel +
            '</td></tr>'
        );
      }

      const fieldGroup = field.fieldGroup;
      if (fieldGroup) {
        fieldGroup.forEach((innerField) => {
          if (
            innerField &&
            (innerField?.type === 'cost-calculation' ||
              innerField?.type === 'cost-calculation-financial-plan')
          ) {
            this.processCostCalculation(
              innerField,
              this.generateModelKey(field, breadcrumbKey),
              hasSumColumn,
              isTotalSumRow,
              maxColumnCount
            );
          } else {
            console.warn('Unknown fieldtype: ', innerField.type);
          }
        });
      }
    } else {
      console.warn(
        'Parameter not initialized or unknown fieldtype: ',
        field?.type
      );
    }
  }

  /**
   * Count the number of all/max columns in a 'cost-calculation-table' or 'formly-group'.
   *
   * @param field the given/current field
   *
   * @returns the count of all columns
   */
  getMaxColumnCount(field: FormlyFieldConfig | undefined): number {
    let maxCount: number = -1;
    if (field) {
      switch (field?.type) {
        case 'cost-calculation-table':
        case 'formly-group':
          const fieldGroup = field?.fieldGroup;
          if (fieldGroup) {
            fieldGroup.forEach((innerField) => {
              let colCount = this.getMaxColumnCount(innerField);
              maxCount = maxCount > colCount ? maxCount : colCount;
            });
          }
          break;
        case 'cost-calculation':
        case 'cost-calculation-financial-plan':
          if (this.getFieldLabel(field)) {
            maxCount = 1;
          }
          const fieldCount = field.fieldGroup?.length;
          if (fieldCount && fieldCount > 0) {
            maxCount = maxCount > 0 ? maxCount + fieldCount : fieldCount;
          }
          break;
        default:
          console.warn('Unknown fieldtype: ', field.type);
          break;
      }
    }
    return maxCount;
  }

  /**
   * Get the value of templateOption point of a field.
   *
   * @param field the given/current field
   *
   * @returns the value of option point
   */
  getFieldPoint(field: FormlyFieldConfig): string {
    if (field?.templateOptions?.point) {
      return field.templateOptions?.point;
    }
    return '';
  }

  /**
   * Get the value of templateOption label of a field.
   *
   * @param field the given/current field
   *
   * @returns the value of option label
   */
  getFieldLabel(field: FormlyFieldConfig): string {
    if (field?.templateOptions?.label) {
      return this.replaceSpaces(field.templateOptions?.label) as string;
    }
    return '';
  }

  /**
   * Get the value of templateOption description of a field.
   *
   * @param field the given/current field
   *
   * @returns the value of option description
   */
  getFieldDescription(field: FormlyFieldConfig): string {
    if (field?.templateOptions?.description) {
      return field.templateOptions?.description;
    }
    return '';
  }

  /**
   * Get the value for the given/current field from the form model.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   * @param defval the default value
   *
   * @returns the value of the field
   */
  getFieldValue(
    field: FormlyFieldConfig,
    breadcrumbKey: string | number | string[] | undefined,
    defval: string | undefined
  ): string {
    if (field?.key) {
      const modelKey = this.generateModelKey(field, breadcrumbKey);
      let fieldValue = this.findValue(modelKey, defval);
      fieldValue = this.formatFieldValue(field, modelKey, fieldValue, defval);
      return fieldValue;
    }
    return '';
  }

  /**
   * Formats the value for the given/current field.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   * @param value the value of the field
   * @param defval the default value
   *
   * @returns the formated value of the field
   */
  formatFieldValue(
    field: FormlyFieldConfig,
    breadcrumbKey: string,
    value: string,
    defval: string | undefined
  ): string {
    if (
      _findIndex(this.fieldFormatters, (formatter) =>
        formatter.breadcrumpKeys.includes(breadcrumbKey)
      ) !== -1
    ) {
      value = this.formatByFieldFormatter(breadcrumbKey, value, defval);
    } else {
      value = this.formatCostFieldValue(field, value, defval);
      value = this.formatDateFieldValue(field, value, defval);
    }
    return value;
  }

  /**
   * Formats the value for the given/current field by a custom field formatter.
   *
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   * @param value the value of the field
   * @param defval the default value
   *
   * @returns the formated value of the field
   */
  formatByFieldFormatter(
    breadcrumbKey: string,
    value: string,
    defval: string | undefined
  ): string {
    const fieldFormatter = _find(this.fieldFormatters, (formatter) =>
      formatter.breadcrumpKeys.includes(breadcrumbKey)
    );
    if (fieldFormatter) {
      value = fieldFormatter.generateFormatedFieldTag(value, defval);
    }
    return value;
  }

  /**
   * Formats the value for the given/current field as currency value.
   *
   * @param field the given/current field
   * @param value the value of the field
   * @param defval the default value
   *
   * @returns the formated value of the field
   */
  formatCostFieldValue(
    field: FormlyFieldConfig,
    value: string,
    defval: string | undefined
  ): string {
    if (field && field.templateOptions && field.templateOptions?.type) {
      // Transform cost values
      const type = field.templateOptions?.type;

      if (type === 'cost' || type === 'cost-cur') {
        if (value && value !== defval && this.isNumeric(value)) {
          value = this.decimalFormatter.format(Number(value));
        } else {
          value = '0,00';
        }

        value = type === 'cost-cur' ? value + ' €' : value;
      }
    }
    return value;
  }

  /**
   * Formats the value for the given/current field as date value.
   *
   * @param field the given/current field
   * @param value the value of the field
   * @param defval the default value
   *
   * @returns the formated value of the field
   */
  formatDateFieldValue(
    field: FormlyFieldConfig,
    value: string,
    defval: string | undefined
  ): string {
    if (field && field?.wrappers) {
      // Transform date values
      if (field.wrappers?.includes('date-wrapper')) {
        if (value && value !== defval && this.isDate(value)) {
          const transformedVal = this.datePipe.transform(value, 'dd.MM.yyyy');
          value = transformedVal ? transformedVal : value;
        }
      }
    }
    return value;
  }

  /**
   * Adding checkbox table tag to html from form template.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   */
  getCheckboxTableTag(
    field: FormlyFieldConfig,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    const currentValue = this.getFieldValue(field, breadcrumbKey, undefined);
    return this.getCheckboxTableTagByValue(
      currentValue,
      this.getFieldLabel(field)
    );
  }

  /**
   * Generates checkbox table tag html string
   *
   * @param checkboxValue true if checkbox is checked else false
   * @param label the label of the checkbox
   *
   * @returns checkbox table tag html string
   */
  getCheckboxTableTagByValue(checkboxValue: boolean | string, label: string) {
    return (
      '<table><tr>' +
      '<td style="width: 5%; border: none;">' +
      this.getCheckboxTag(checkboxValue as string) +
      '</td><td style="width: 95%; border: none;">' +
      label +
      '</td></tr></table>'
    );
  }

  /**
   * Checks if a field has template value tags.
   *
   * @param field the given/current field
   *
   * @returns true if the field contains template value tags else false
   */
  containsTemplateValueTags(field: FormlyFieldConfig): boolean {
    if (this.getTemplateValueTags(field)) {
      return true;
    }
    return false;
  }

  /**
   * Adding all template value tags to html from form template.
   *
   * @param field the given/current field
   */
  writeTemplateValueTags(field: FormlyFieldConfig) {
    let templateValueTags = this.getTemplateValueTags(field);
    if (templateValueTags) {
      this.writeHtml(templateValueTags);
    }
  }

  /**
   * Adding sub-group template value tags (with custom styles) to html from form template.
   *
   * @param field the given/current field
   */
  writeSubGroupTemplateValueTags(field: FormlyFieldConfig) {
    let templateValueTags = this.getTemplateValueTags(field);
    if (templateValueTags) {
      this.writeHtml(
        "<span style='margin-bottom: 8px'>" + templateValueTags + '</span>'
      );
    }
  }

  /**
   * Get all template value tags as html string.
   *
   * @param field the given/current field
   *
   * @returns all template value tags as html string
   */
  getTemplateValueTags(field: FormlyFieldConfig): string {
    let val = '';
    if (field) {
      val = this.getFieldPoint(field);
      val += this.getFieldLabel(field);
      val += this.getFieldDescription(field);
    }
    return val;
  }

  /**
   * Adding point tag to html from form template.
   *
   * @param field the given/current field
   */
  writePointTag(field: FormlyFieldConfig) {
    const val = this.getFieldPoint(field);
    if (val) {
      this.writeHtml('<div class="point">' + val + '</div>');
    }
  }

  /**
   * Adding label tag to html from form template.
   *
   * @param field the given/current field
   */
  writeLabelTag(field: FormlyFieldConfig) {
    const val = this.getFieldLabel(field);
    if (val) {
      this.writeHtml('<div class="label">' + val + '</div>');
    }
  }

  /**
   * Adding description tag to html from form template.
   *
   * @param field the given/current field
   */
  writeDescriptionTag(field: FormlyFieldConfig) {
    const val = this.getFieldDescription(field);
    if (val) {
      this.writeHtml('<div class="description">' + val + '</div>');
    }
  }

  /**
   * Get checkbox tag as html string.
   *
   * @param currentValue boolean string (if not '1' or 'true' = false)
   *
   * @returns the checkbox tag as html string
   */
  getCheckboxTag(currentValue: string) {
    if (currentValue) {
      return (
        '<img class="checkbox-img" src="' + this.checkboxCheckedRef + '" />'
      );
    } else {
      return '<img class="checkbox-img" src="' + this.checkboxRef + '" />';
    }
  }

  /**
   * Get radio button table tag as html string.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   *
   * @returns the radio button table tag as html string.
   */
  getRadioButtonValue(
    field: FormlyFieldConfig,
    breadcrumbKey: string | number | string[] | undefined
  ): string {
    const currentValue = this.getFieldValue(field, breadcrumbKey, undefined);
    const options = field?.templateOptions?.options;
    let html = '<table>';
    if (options) {
      options.forEach((option) => {
        const label = option.label;
        const value = option.value;
        html +=
          '<tr>' +
          '<td style="width: 5%; border: none;">' +
          this.getRadioButtonTag(value, currentValue) +
          '</td><td style="width: 95%; border: none;">' +
          label +
          '</td></tr>';
      });
    }
    html += '</table>';
    return html;
  }

  /**
   * Get radio button tag as html string.
   *
   * @param optionValue the value of an possible option
   * @param currentValue the current value in the form model
   *
   * @returns the radio button tag as html string.
   */
  getRadioButtonTag(optionValue: string, currentValue: string) {
    if (currentValue !== optionValue) {
      return (
        '<img class="radiobutton-img" src="' + this.radioButtonRef + '" />'
      );
    } else {
      return (
        '<img class="radiobutton-img" src="' +
        this.radioButtonActiveRef +
        '" />'
      );
    }
  }

  /**
   * Writes a html string to the globel html member.
   *
   * @param content the html content string
   */
  writeHtml(content: string) {
    this.html += content;
  }

  /**
   * Get the value for a breadcrump key chain.
   *
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   * @param defval the default value
   *
   * @returns the value
   */
  findValue(breadcrumbKey: string, defval: string | undefined): string {
    let jsonObject = this.formModel;
    defval = defval ?? '';
    let breadcrumps: string[] = breadcrumbKey.split('.');
    for (const breadcrump of breadcrumps) {
      if (
        jsonObject[breadcrump] === null ||
        jsonObject[breadcrump] === undefined
      )
        return defval;
      jsonObject = jsonObject[breadcrump];
    }
    return jsonObject !== null && jsonObject.toString().trim() !== ''
      ? jsonObject
      : defval;
  }

  /**
   * Get the value for a model key.
   *
   * @param key the model key (breadcrump)
   * @param defval the default value
   *
   * @returns the value for the key
   */
  findValueByKey(key: string, defval: string | undefined): string | undefined {
    const breadcrump = this.getBreadcrumbByKey(key, undefined, undefined);
    if (breadcrump) {
      return this.findValue(breadcrump, defval);
    }
    return defval;
  }

  /**
   * Get a child field by breadcrump key chain starting from given
   * field of form moldel if field is not given.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   *
   * @returns the child field
   */
  findField(
    field: FormlyFieldConfig | FormlyFieldConfig[] | undefined,
    breadcrumbKey: string
  ): FormlyFieldConfig | undefined {
    if (!field) {
      // If no field is defined, we search over all fieldGroups
      field = this.fieldGroup;
    }

    if (field && breadcrumbKey) {
      breadcrumbKey = breadcrumbKey.trim();
      if (isArray(field)) {
        for (const innerField of field) {
          let fieldFound = this.findField(innerField, breadcrumbKey);
          if (fieldFound) {
            return fieldFound;
          }
        }
      } else {
        if (field.key) {
          const fieldKey = field.key.toString().trim();
          if (fieldKey === breadcrumbKey) {
            // We found our field!
            return field;
          } else if (breadcrumbKey.startsWith(fieldKey)) {
            // Looks like we are on the right way,
            // but we have to go on searching in the subgroup,
            // but first let us remove the current breadcrump!
            breadcrumbKey = breadcrumbKey.replace(fieldKey, '');
            breadcrumbKey = breadcrumbKey.startsWith('.')
              ? breadcrumbKey.replace('.', '')
              : breadcrumbKey;
            const fieldInGroupFound = this.findField(
              field.fieldGroup,
              breadcrumbKey
            );
            if (fieldInGroupFound) {
              // We found our field in the subgroup!
              return fieldInGroupFound;
            }
          } else if (
            breadcrumbKey.endsWith('.' + fieldKey) ||
            breadcrumbKey.includes('.' + fieldKey + '.')
          ) {
            // We could be on the right way,
            // but maybe the breadcrumbKey has wrong parents defined!
            console.warn(
              'Incorrectly defined breadcrumb path! breadcrumbKey : ',
              breadcrumbKey,
              ' | fieldKey: ',
              fieldKey
            );
          }
        } else if (field.fieldGroup) {
          // We have to go on searching in the subgroup
          const fieldInGroupFound = this.findField(
            field.fieldGroup,
            breadcrumbKey
          );
          if (fieldInGroupFound) {
            // We found our field in the subgroup!
            return fieldInGroupFound;
          }
        }
      }
    }

    return undefined;
  }

  /**
   * Get the field for a model key.
   *
   * @param key the model key (breadcrump)
   *
   * @returns the field
   */
  findFieldByKey(key: string): FormlyFieldConfig | undefined {
    const breadcrump = this.getBreadcrumbByKey(key, undefined, undefined);
    if (breadcrump) {
      return this.findField(undefined, breadcrump);
    }
    return undefined;
  }

  /**
   * Creates the breadcrum key by a given model key.
   *
   * @param key the model key (breadcrump)
   * @param breadcrumb the breadcrump key chain to the field value in the form model
   * @param model the form model
   *
   * @returns the breadcrum key
   */
  getBreadcrumbByKey(
    key: string,
    breadcrumb: string | undefined,
    model: any | undefined
  ): string | undefined {
    if (!model) {
      // If no model is defined, we search over the complete model
      model = this.formModel;
    }

    for (const item in model) {
      const innerObj = model[item];
      if (item === key) {
        return !breadcrumb ? item : breadcrumb + '.' + item;
      } else if (innerObj) {
        const newBreadcrumb = this.getBreadcrumbByKey(
          innerObj,
          key,
          !breadcrumb ? item : breadcrumb + '.' + item
        );
        if (newBreadcrumb && newBreadcrumb.includes(key)) {
          return newBreadcrumb;
        }
      }
    }
    return breadcrumb;
  }

  /**
   * Creates the breadcrum key for a given field with regard to the given breadcrum key.
   *
   * @param field the given/current field
   * @param breadcrumbKey the breadcrump key chain to the field value in the form model
   *
   * @returns  the created breadcrum key
   */
  generateModelKey(
    field: FormlyFieldConfig | undefined,
    breadcrumbKey: string | number | string[] | undefined
  ) {
    const fieldKey = field?.key ? (field?.key as string) : '';
    const breadcrumbKeyExtension = fieldKey ? '.' + fieldKey : '';
    return breadcrumbKey
      ? (breadcrumbKey as string) + breadcrumbKeyExtension
      : fieldKey;
  }

  /**
   * Checks if a given value is nummeric.
   *
   * @param value the given value
   *
   * @returns true if the given value is nummeric else false
   */
  isNumeric(value: string | number): boolean {
    return value != null && value !== '' && !isNaN(Number(value.toString()));
  }

  /**
   * Checks if a given value is a date.
   *
   * @param value the given value
   *
   * @returns true if the given value is a date else false
   */
  isDate(value: string) {
    return !isNaN(Date.parse(value));
  }

  /**
   * Trims and replaces blanks > 1 in a given string value.
   *
   * @param value the value
   *
   * @returns the result value
   */
  replaceSpaces(value: string | undefined): string | undefined {
    return value ? value.trim().replace(/\s\s+/g, ' ') : value;
  }
}
