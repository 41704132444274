import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProjectAction, ProjectActionData } from '../interfaces/project-action';

@Injectable({ providedIn: 'root' })
export class ProjectActionService {
  private subject = new Subject<ProjectAction | null>();

  setAction(
    action:
      | 'create'
      | 'request'
      | 'submit'
      | 'edit'
      | 'requestEdit'
      | 'delete'
      | 'deleteFile'
      | 'deleteRequest'
      | 'deleteForm'
      | 'createRequest'
      | 'uploadFile'
      | 'createInquiryTopic'
      | 'postMessage'
      | 'createDocument'
      | 'checkAnswered'
      | 'displayDeliverDialog'
      | 'openFile'
      | 'withdrawProject'
      | 'deleteProject'
      | 'addDeputy'
      | 'inactivateDeputy'
      | 'goToLogin'
      | 'changeProjectState'
      | 'updateProject'
      | 'markFormAsPristine'
      | 'executeActionOnFormData'
      | 'saveFormDocument'
      | 'assignDocumentToRequest'
      | 'archiveInquiryTopic',
    data: ProjectActionData
  ) {
    this.subject.next({ action, data });
  }

  clear() {
    this.subject.next(null);
  }

  onAction() {
    return this.subject.asObservable();
  }
}
